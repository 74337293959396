angular.module('notification', [])

.factory('Notification',
    ['Restangular',
    function (Restangular) {

    return {
        all: function (params) {
            return Restangular.all('notifications').getList(params);
        },

        add: function (notification) {
            return Restangular.all('notifications').post(notification);
        },

        todo: function () {
            return Restangular.all('notifications').customGET('todo');
        },

        done: function (param) {
            if (Array.isArray(param)) {
                return Restangular.all('notifications').customPUT(param, 'done');
            }
            return Restangular.one(`notifications/${param}`).customPUT(null, 'done');
        },

        undone: function (id) {
            return Restangular.one(`notifications/${id}`).customPUT(null, 'undone');
        }
    };
}]);