angular.module('editingPlace', [])
    .factory('Editing',
        [ 'Restangular', '$q',
            function (Restangular, $q) {
                return {
                    get_listing: function(listing_id) {             //GET: one listing
                        return Restangular
                            .one('airbnb')
                            .one('listings', listing_id)
                            .get({}, {'X-LISTING-ID': listing_id});
                    },
                    edit_listing: function(listing_id, listing) {
                        return Restangular
                            .one('airbnb')
                            .one('listings', listing_id)
                            .customPUT(listing, '', '', {'X-LISTING-ID': listing_id});
                    },
                    get_description: function(listing_id){          //GET: listing_description
                        return Restangular
                            .one('airbnb')
                            .one('listing_descriptions')
                            .get({listing_id}, {'X-LISTING-ID': listing_id})
                    },
                    edit_description: function(listing_id, listing_description, locale) {
                        return Restangular
                            .one('airbnb')
                            .one('listing_descriptions', listing_id)
                            .one(locale)
                            .customPUT(listing_description, '', '', {'X-LISTING-ID': listing_id});
                    },
                    get_booking_settings: function(listing_id) {     //GET: booking_settings
                        return Restangular
                            .one('airbnb')
                            .one('booking_settings', listing_id)
                            .get({}, {'X-LISTING-ID': listing_id});
                    },
                    edit_booking_settings: function(listing_id, listing_booking_settings) {
                        console.log(listing_booking_settings)
                        return Restangular
                            .one('airbnb')
                            .one('booking_settings', listing_id)
                            .customPUT(listing_booking_settings, '', '', {'X-LISTING-ID': listing_id});
                    },
                    get_pricing_settings: function(listing_id) {     //GET: pricing_settings
                        return Restangular
                            .one('airbnb')
                            .one('pricing_settings', listing_id)
                            .get({}, {'X-LISTING-ID': listing_id});
                    },
                    edit_pricing_settings: function(listing_id, listing_pricing_settings) {
                        return Restangular
                            .one('airbnb')
                            .one('pricing_settings', listing_id)
                            .customPUT(listing_pricing_settings, '', '', {'X-LISTING-ID': listing_id});
                    },
                    edit_currency: function(listing_id, listing_currency) {
                        return Restangular
                            .one('airbnb')
                            .one('pricing_settings', listing_id)
                            .customPUT({'listing_currency': listing_currency}, '', '', {'X-LISTING-ID': listing_id});
                    },
                    get_rooms: function(listing_id) {               //GET: all rooms
                        return Restangular
                            .all('airbnb')
                            .one('listing_rooms')
                            .customGET('',  {'listing_id': listing_id}, {'X-LISTING-ID': listing_id});
                    },
                    get_availability_rules: function(listing_id) {     //GET: availability_rules
                        return Restangular
                            .one('airbnb')
                            .one('availability_rules', listing_id)
                            .get({}, {'X-LISTING-ID': listing_id});
                    },
                    edit_availability_rules: function(listing_id, listing_availability_rules) {
                        return Restangular
                            .one('airbnb')
                            .one('availability_rules', listing_id)
                            .customPUT(listing_availability_rules, '','', {'X-LISTING-ID': listing_id});
                    },
                    get_all_listing_photos: function(listing_id) {     //GET: listing_photos
                        return Restangular
                        .all('airbnb')
                        .one('listing_photos')
                        .customGET('', {'listing_id': listing_id}, {'X-LISTING-ID': listing_id});
                    },
                    add_photo: function(listing_id, listing_photo) {
                        var datas = new FormData();
                        datas.append('image', listing_photo);
                        datas.append('listing_id', listing_id);

                        return Restangular
                        .one('airbnb')
                        .one('helper')
                        .one('listing_photos')
                            .withHttpConfig({transformRequest: angular.identity})
                            .customPOST(datas, '', undefined, {'Content-Type': undefined, 'X-LISTING-ID': listing_id});
                    },
                    delete_photo: function (photo_id, listing_id) {
                        return Restangular
                        .one('airbnb')
                        .one('listing_photos', photo_id)
                        .remove('', {'X-LISTING-ID': listing_id})
                    },
                    edit_photo: function (photo_id, sort_order, listing_id) {
                        return Restangular
                        .one('airbnb')
                        .one('listing_photos', photo_id)
                        .customPUT({'sort_order': sort_order}, '','', {'X-LISTING-ID': listing_id});
                    },
                    list_place: function (listing_id) {
                        return Restangular
                            .one('airbnb')
                            .one('helper')
                            .one('listed_listings', listing_id)
                            .customPOST({}, '', null, {'X-LISTING-ID': listing_id});
                    },
                    unlist_place: function (listing_id) {
                        return Restangular
                            .one('airbnb')
                            .one('helper')
                            .one('listed_listings', listing_id)
                            .remove('', {'X-LISTING-ID': listing_id});
                    },
                    update_room_numbers: function (listing_id, summary) { //PUT: update rooms numbers
                        return Restangular.one('airbnb').one('listings', listing_id).customPUT(
                            {
                                'bedrooms': summary.bedroom_number,
                                'bathrooms': summary.bathroom_number,
                                'beds': summary.bed_number,
                            }, '', '', {'X-LISTING-ID': listing_id});
                    },
                    create_room: function (room, listing_id) { //POST: create new room
                        return Restangular.one('airbnb').one('listing_rooms').customPOST(
                            {
                                'beds': room.beds,
                                'listing_id': room.listing_id,
                                'room_type': room.room_type,
                                'is_private': room.is_private,
                                'room_amenities': room.room_amenities,
                                'room_number': room.room_number
                            }, '', '', {'X-LISTING-ID': listing_id});
                    },
                    delete_room: function (listing_id,room_id ) { //DELETE: delete a room
                        return Restangular.one('airbnb').one('listing_rooms', listing_id).all(room_id).remove({}, {'X-LISTING-ID': listing_id})
                    },
                    update_room: function (listing_id,room_id, room ) { //PUT: update room
                        return Restangular.one('airbnb').one('listing_rooms', listing_id).all(room_id).customPUT(
                            {
                                'beds': room.beds,
                                'room_type': room.room_type,
                                'is_private': room.is_private,
                                'room_amenities': room.room_amenities,
                            }, '', '', {'X-LISTING-ID': listing_id});
                    },
                }
            }
        ]
    )
