angular.module('user', [])

.constant('userType', {
  'ADMIN': {'id': 0, 'label': 'Admin'},
  'HOST': {'id': 1, 'label': 'Host'},
  'MAID': {'id': 2, 'label': 'Maid'},
})

.factory('Account',
    ['$q', 'Restangular', '$cookies', 'userType',
    function($q, Restangular, $cookies, userType) {

    var restangular = Restangular.one('account');

    var current_account;

    var baseAccount = {

        signout: function () {
            $cookies.remove('token');
            current_account = null;
            return current_account;
        },

        places: function () {
            return restangular.all('places').getList();
        },

        email_confirm: function () {
            return Restangular.one('account').one('email_confirm').get();
        },

        completed: function () {
            if (!current_account)
                return;

            if (!current_account.confirmed)
                return;

            if (!current_account.host.phone)
                return;

            return true;
        },

        update : function (account) {
            current_account = account;
        },
    };

    Restangular.extendModel('account', function (model) {
        return angular.extend(model, baseAccount);
    });

    return {
        current: function () {
            var defer = $q.defer();

            if (current_account)
                defer.resolve(current_account);
            else
                restangular.get()
                    .then(function (account) {
                        current_account = account;
                        defer.resolve(account);
                    }, function (error) {
                        defer.reject(error);
                    });

            return defer.promise;
        },

        current_exists: function () {
            if (current_account)
                return true;

            return false;
        },

        signup: function (account) {
            return Restangular.all('account').post(account);
        },

        confirm: function (user_id, token) {
            return Restangular
                        .one('account', user_id)
                        .one('confirm', token).get();
        },

        signin: function (email, password) {
            var defer = $q.defer();

            restangular.customPOST({email: email, password: password}, 'signin')
                       .then(function (r) {
                            $cookies.put('token', r.access_token);

                            restangular.get()
                                       .then(function (account) {
                                            current_account = account;
                                            defer.resolve(account);
                                        }, function (error) {
                                            defer.reject(error);
                                        });
                        },
                        function (error) {
                            defer.reject(error);
                        });

            return defer.promise;
        },

        password_reset: function (email) {
            return restangular.one('password-reset', email).get();
        },

        password_create: function (id) {
          return restangular.one('password-create', id).get();
        },

        password_change: function (user_id, token, password) {
            return Restangular.one('account', user_id)
                              .customPOST({'token' : token, 'password' : password}, 'password');
        },

        social_login: function (token) {
            var defer = $q.defer();
            $cookies.put('token', token);

            restangular.get()
                       .then(function (account) {
                            current_account = account;
                            defer.resolve(account);
                        }, function (error) {
                            defer.reject(error);
                        });

            return defer.promise;
        },

        profile_update: function (account, billing_address) {
            return Restangular.one('account', account.id)
                        .customPUT(account, 'profile', {'billing_address' : billing_address});
        },

        admins: function () {
           return Restangular.one('account', account.id)
                        .customPUT(account, 'profile', {'billing_address' : billing_address});
        },
    };
}])

.factory('Admin',
    ['Restangular',
    function(Restangular) {

    return {
        all: function () {
           return Restangular.all('admins').getList();
        }
    };
}]);
