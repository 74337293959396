angular.module('maid')

  .controller('MaidMissions',
    ['$state', 'MaidMission', 'Mission', 'Report',
    'Modal', 'actions', 'missionStatus', '$uibModal', '$q', '$scope', '$window',
    function ($state, MaidMission, Mission, Report,
              Modal, actions, missionStatus, $uibModal, $q, $scope, $window) {
    var vm = this;

    vm.params = {
      place_id: $state.params.place_id,
      action: null,
      search: null,
      page: 1,
      per_page: 20
    }

    vm.actions = actions;
    vm.missions = {};
    vm.missionsInterval = [];
    vm.missionsIntervalDayjs = []
    vm.filters = {
      date: new Date(),
      maid_id: null,
      code: ''
    }
    vm.missionStatus = missionStatus;
    vm.mobile = false

    vm.init = function(){
      $window.matchMedia('screen and (min-width: 768px)').addListener(function(e) {
        vm.mobile = !e.matches
        $scope.$apply()
      })

      vm.mobile = $window.innerWidth < 768
    }

    vm.page_change = function () {
        vm.missionsInterval = []
        vm.missionsIntervalDayjs = []
        vm.missions = {}
        var dateStart = null
        const filterDate = vm.filters.code === '' ? vm.filters.date : dayjs()

        MaidMission.all({
                date: dayjs(filterDate).format('YYYY-MM-DD'),
                maid_id: vm.filters.maid_id,
                code: vm.filters.code
            })
            .then(d => {
                if (vm.filters.code) {
                    dateStart = d[0].available_at
                    const date = dayjs(dateStart).add(0, 'day')
                    vm.missionsInterval.push(date.format('DD/MM/YYYY'))
                    vm.missionsIntervalDayjs.push(date)
                } else {
                    dateStart = filterDate;

                    for (let i = 0; i < 7; i++) {
                        const date = dayjs(dateStart).add(i, 'day')
                        vm.missionsInterval.push(date.format('DD/MM/YYYY'))
                        vm.missionsIntervalDayjs.push(date)
                    }
                }

                for(let i = 0; i < vm.missionsInterval.length; i++) {
                    vm.missions[vm.missionsInterval[i]] = d.filter(m => {
                        const day = dayjs(vm.missionsIntervalDayjs[i])
                        return dayjs(m.available_at).isSame(day, 'day')
                    });
                }
            })
    };

    vm.date_change = function () {
        vm.page_change()
    };

    vm.mission_detail = function (mission) {
        var modal_scope = $scope.$new();
        modal_scope.mission = mission;

        modal_scope.modal = $uibModal.open({
          templateUrl: require('./mission.modal.html'),
          scope: modal_scope
        });

        modal_scope.valid = function () {
          deferred.resolve(modal_scope.code.value);
          modal_scope.modal.close();
        };
    }

    vm.mission_report = function (mission) {
        var modal_scope = $scope.$new();
        modal_scope.mission = mission;
        modal_scope.report = mission.report ? mission.report : {};

        modal_scope.modal = $uibModal.open({
            templateUrl: require('./report.modal.html'),
            scope: modal_scope,
            backdrop: 'static'
        });

        modal_scope.upload_callback = function (file, callback) {
            var datas = new FormData();
            datas.append('file', file);

            Report.upload(datas)
                .then(function (response) {
                    if (!modal_scope.report.photos)
                        modal_scope.report.photos = [];

                    modal_scope.report.photos.push({
                        url : response.url,
                        id: response.id
                    });

                    callback();
                }, function () { callback(); });
        };

        modal_scope.valid = function (form, report) {
            form.$setSubmitted();

            if (!form.$valid)
                return;

            Mission.set(mission.id, report)
                .then(function () {
                    mission.status = missionStatus.DONE.id;
                    modal_scope.modal.close();
                })
        }

        modal_scope.delete_photo = function(photo) {
          Report.delete_photo(photo.id).then(function() {
            modal_scope.report.photos = modal_scope.report.photos.filter(p => p.id !== photo.id)
          })
        }
    };

    vm.init();
    vm.page_change();
  }]);
