angular.module('payment', [])

  .factory('Payment',
    ['Restangular',
      function (Restangular) {
        return {
          all: function (params) {
            return Restangular.all('host-payments').customGET('', params);
          },

          refresh: function (date, with_payouts) {
            return Restangular.one('host-payments/calculate')
                .customPOST(null, date, {with_payouts: with_payouts});
          },

          report: function (params) {
            return Restangular.one('host-payments/report/' + params.host_id + '/' + params.date).get()
          },

          report_send: function (params) {
            return Restangular.one('host-payments/report/' + params.host_id + '/' + params.date + '/send')
              .customPOST('', null)
          },

          update_status: function (payment, date) {
            return Restangular.one('host-payments/' + payment.host_id + '/' + date + '/status')
              .customPUT({status: payment.status})
          },

          done: function (payment_id) {
            return Restangular.one('payments', payment_id).customGET('done');
          },

          get_fees: function (place_id) {
            return Restangular.all('fees').getList({place_id: place_id})
          },

          add_fee: function (fee) {
            return Restangular.one('fees').customPOST(fee);
          },

          update_fee: function (fee) {
            return Restangular.one('fees/' + fee.id).customPUT(fee);
          },

          delete_fee: function (fee_id) {
            return Restangular.one('fees/' + fee_id).customDELETE()
          }
        };
      }]);
