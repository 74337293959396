angular.module('place')

  .directive('pictureUrl', function () {
    return {
      restrict: 'A',
      scope: {
        url: '@pictureUrl'
      },
      link: function (scope, element, attrs) {
        element.css('background-image', 'url(' + scope.url + ')');
      }
    };
  })

  .filter('thumbnail', function () {
    return function (place) {
      if (!place || !place.main_picture) return;
      return `url(${place.main_picture}?thumbnail=true)`;
    };
  })

  .controller('Places',
    ['Place', 'Modal', '$stateParams', '$timeout', '$scope',
      function (Place, Modal, $stateParams, $timeout, $scope) {
        var vm = this;

        vm.params = {
          page: 1,
        };

        vm.page_change = function () {
          Place.all(vm.params)
            .then(function (places) {
              vm.places = places;
            });
        };
        vm.page_change();

        $scope.$watch('vm.params.q', function (qnew, qold) {
          if (qnew === qold)
            return;

          if (qnew) {
            vm.page_change();
          } else {
            vm.params.page = 1;
            vm.page_change();
          }
        });

        vm.clean = function (place) {
          if (place.clean)
            return;

          var title = 'Cleaning - place <b>' + place.name + '</b>';
          var body = 'Set the place as cleaned';

          Modal.confirm(title, body)
            .then(function () {
              Place.clean(place.id, true)
                .then(function () {
                  place.clean = true;
                  vm.success = true;

                  $timeout(function () {
                    vm.success = false;
                  }, 1000);
                });
            });
        };

      }])
  .controller('PlaceReservations', ['$scope', '$stateParams', '$uibModal', '$filter', 'Place', 'account',
    function ($scope, $stateParams, $uibModal, $filter, Place, account) {
      var vm = this;
      vm.account = account;
      vm.page = 1;
      vm.perPage = 20;

      vm.loadPlace = function(){
        return Place.get($stateParams.place_id, ['reservations'])
            .then(function (place) {
              vm.place = place;
            })
      }

      vm.loadReservations = function(){
        Place.bookings($stateParams.place_id, vm.page).then(function(bookings){
          vm.place['reservations'] = bookings.items
          vm.total = bookings.total;
        })
      }

      vm.loadPlace().then(function(){
        vm.loadReservations();
      });
    }])
  .controller('Place',
    ['$scope', '$stateParams', '$uibModal', '$filter', 'Place', 'Modal', 'Availability', 'account', '$timeout', 'reservationStatus', 'Restangular', 'Payment',
      function ($scope, $stateParams, $uibModal, $filter, Place, Modal, Availability, account, $timeout, reservationStatus, Restangular, Payment) {
        var vm = this;
        vm.account = account;
        vm.current_id = false;
        vm.periods = [];
        vm.reservations = [];

        Place.get($stateParams.place_id)
          .then(function (place) {
            vm.place = place;
            Place.availabilities(place.id)
              .then(function (availability) {
                vm.periods = availability.periods;
                vm.reservations = availability.reservations;
                vm.availability_main = place.availability;
              });

            Payment.get_fees(place.id).then(function (entries) {
              vm.exceptionnal_entries = entries
            });
          });

        vm.clean = function (place) {
          if (place.clean)
            return;

          var title = 'Cleaning - place <b>' + place.name + '</b>';
          var body = 'Set the place as cleaned';

          Modal.confirm(title, body)
            .then(function () {
              Place.clean(place.id, true)
                .then(function () {
                  place.clean = true;
                  vm.success = true;

                  $timeout(function () {
                    vm.success = false;
                  }, 1000);
                });
            });
        };

        vm.comments = function (comments) {
          Modal.inform('Comments', comments);
        };

        vm.current_init = function (reservation) {
          if (vm.current_id)
            return;

          if (reservation.status === reservationStatus.PENDING.id)
            vm.current_id = reservation.id;
        };

        vm.reservation_display = function (reservation) {
          var address = vm.place.address;
          var title = "Reservation";
          var body = '<p><b> Code </b> : ' + reservation.code + '</p>' +
            '<p><b> Address </b> : ' + address.street + ', ' +
            address.postal_code + ' ' + address.city + ' - ' +
            address.country + '</p>' +
            '<p><b> From </b> : ' + $filter('date')(reservation.from_datetime, 'short') + '</p>' +
            '<p><b> To </b> : ' + $filter('date')(reservation.to_datetime, 'short') + '</p>' +
            '<p><b> Guest name </b> : ' + reservation.guest.firstname + ' ' + reservation.guest.lastname + '</p>' +
            '<p><b> Guest email </b> : ' + reservation.guest.email + '</p>' +
            '<p><b> Guest phone </b> : ' + reservation.guest.phone + '</p>' +
            '<p><b> Guests </b> : ' + reservation.guest_count + '</p>' +
            '<p><b> Beds </b> : ' + reservation.bed_count + '</p>' +
            '<p><b> Cleaner </b> : ' + reservation.maid_name + '</p>' +
            '<p><b> KNF % </b> : ' + reservation.knf_percentage + '</p>' +
            '<p><b> Night cost </b> : ' + reservation.night_price + ' ' + reservation.currency + '</p>' +
            '<p><b> Cleaning cost </b> : ' + reservation.cleaning_cost + ' ' + reservation.currency + '</p>' +
            '<p><b> NET Rent </b> : ' + reservation.rent_net + ' ' + reservation.currency + '</p>';
          Modal.inform(title, body);
        };

        vm.period_set = function () {
          var modal_scope = $scope.$new();

          modal_scope.period = {
            from_date: null,
            to_date: null,
            availability: false
          }

          modal_scope.modal = $uibModal.open({
            templateUrl: require('./period.form.html'),
            scope: modal_scope,
            backdrop: 'static'
          });

          modal_scope.check_startdate = function (form, from_date) {
            var today = new Date();

            form['from_date'].$setValidity('past_date', from_date.getTime() > today.getTime());
          };

          modal_scope.check_period = function (form, field, period) {
            if (!period.to_date || !period.from_date)
              return;

            form[field].$setValidity('inverted_dates', period.to_date.getTime() > period.from_date.getTime());
          };

          modal_scope.valid = function (form, period) {
            form.$setSubmitted();

            if (!form.$valid)
              return;

            vm.period = period;
            modal_scope.modal.close();
          };
        };

        vm.availabity_change = function (availability) {
          var title = 'General availability';
          var body = 'Your specific configurations will be lost.';

          Modal.confirm(title, body)
            .then(function () {
              vm.periods = [];
              vm.place.availability = availability;
              vm.save();
            }, function () {
              vm.availability_main = !vm.availability_main;
            });

        };

        vm.save = function () {
          var params = {
            'availability': vm.place.availability,
            'periods': vm.periods
          };

          Place.availabilities(vm.place.id, params)
            .then(function () {
              vm.success = "Place availability updated successfully";

              $timeout(function () {
                vm.success = false;
              }, 1000);
            });
        };

        vm.delete = function (reservation) {
          var title = 'Delete reservation';
          var body = 'Confirm the permanent deletion of the reservation';

          Modal.confirm(title, body).then(function () {

            Restangular.restangularizeElement(null, reservation, 'reservations')
              .remove()
              .then(function () {
                var total = vm.place.reservations.total - 1;
                vm.place.reservations = _.without(vm.place.reservations, reservation);
                vm.place.reservations.total = total;
              });
          });
        };

        vm.delete_exceptionnal_entry = function (entry) {
          var title = 'Delete exeptionnal entry';
          var body = 'Confirm the permanent deletion of the exceptionnal entry';
          Modal.confirm(title, body)
            .then(function () {
                Payment.delete_fee(entry.id).then(() => {
                  Payment.get_fees($stateParams.place_id).then(function (entries) {
                    vm.exceptionnal_entries = entries
                  });
                })

            });
        };

        vm.create_or_update_exceptionnal_entry = function (entry) {
          var modal_scope = $scope.$new();
          modal_scope.creating = !entry;

          modal_scope.entry = entry || { name: '', description: '', date: null, amount: null, place_id: $stateParams.place_id };
          modal_scope.entry = Object.assign({}, {}, modal_scope.entry)
          if(modal_scope.entry.date){
            modal_scope.entry.date = new Date(modal_scope.entry.date)
          }

          modal_scope.modal = $uibModal.open({
            templateUrl: require('./exceptionnal_entry.modal.html'),
            scope: modal_scope
          });

          modal_scope.valid = function (form) {
            form.$setSubmitted();

            if (!form.$valid)
              return;

            var fn = !modal_scope.entry.id ? Payment.add_fee : Payment.update_fee

            fn(modal_scope.entry)
              .then(function(res) {
                Payment.get_fees($stateParams.place_id)
                  .then(function (entries) {
                    vm.exceptionnal_entries = entries
                  });
              });

            modal_scope.modal.close();
          };
        }
      }])

  .directive('placeHeader',
    ['$state', 'Modal', 'FormManager', '$uibModal', 'Place', 'placeStates', 'configs', 'Maid',
      function ($state, Modal, FormManager, $uibModal, Place, placeStates, configs, Maid) {
        return {
          restrict: 'A',
          scope: {
            place: '=',
            user: '='
          },
          templateUrl: require('./header.html'),
          link: function (scope, element) {
            element.addClass('content place');

            scope.delete = function (place) {
              var title = 'Delete place <b>' + place.name + '</b>';
              var body = '<p> Please confirm the deletion of the informations of the place. </p>' +
                '<p> The data will be permanently lost. </p>';

              Modal.confirm(title, body)
                .then(function () {
                  Place.delete(place.id)
                    .then(function () {
                      if (scope.user && scope.user.admin)
                        $state.go('app.hosts.properties', false, {reload: true});
                      else
                        $state.go('app.properties', false, {reload: true});
                    })

                });
            };

            scope.completion = function () {
              if (!scope.place)
                return;

              if (scope.completion_percentage !== undefined)
                return scope.completion_percentage;

              scope.completion_percentage = FormManager.completion_total_get(scope.place.completion);

              return scope.completion_percentage;
            };

            scope.airbnb_link = function(place) {
              var modal_scope = scope.$new();
              modal_scope.place = place;

              if( modal_scope.place.airbnb_connection === null) {
                modal_scope.place.airbnb_connection = false
              }

              modal_scope.modal = $uibModal.open({
                templateUrl: require('./airbnb_link.html'),
                scope: modal_scope,
                backdrop: 'static'
              });

              modal_scope.valid = function (form) {
                form.$setSubmitted();

                if (!form.$valid)
                return;

                modal_scope.place.listing_id = modal_scope.place.listing_id.toString()

                Place.airbnb_link(place.id, {airbnb_connection: modal_scope.place.airbnb_connection, listing_id: modal_scope.place.listing_id})
                  .then(function () {
                    place.listing_id = modal_scope.place.listing_id;
                    place.airbnb_connection = modal_scope.place.airbnb_connection;
                    modal_scope.modal.close();
                  }).catch (function (response) {
                    console.log('An error occurs. Unable to connect/disconnect place. Error: ', response)
                  })
              }
            }

            scope.parameters = function (place) {
              var modal_scope = scope.$new();
              modal_scope.place = place;
              modal_scope.states = placeStates;
              modal_scope.day_hours = [];

              for (var i = 8; i <= 24; i++)
                modal_scope.day_hours.push(i);

              modal_scope.modal = $uibModal.open({
                templateUrl: require('./parameters.html'),
                scope: modal_scope,
                backdrop: 'static'
              });

              modal_scope.valid = function (form) {
                form.$setSubmitted();

                if (!form.$valid)
                  return;

                Place.configure(place, [
                  'name',
                  'airbnb_link',
                  'state',
                  'airbnb_ical_link',
                  'reservation_email_in_b7',
                  'reservation_email_out_a1',
                  'reservation_comments',
                  'maid',
                  'maid_duration',
                  'maid_access_informations',
                  'maid_additional_informations',
                  'maid_sms',
                  'checkin_hour',
                  'checkout_hour',
                  'reservation_guest_sms',
                  'cleaning_cost',
                  'cleaning_byhost',
                  'airbnb_connection',
                  'instant_booking',
                ])
                  .then(function () {
                    modal_scope.modal.close();
                    return Place.divergent_reservations(place.id)
                  })
                  .then(function (divergent_reservations) {
                    modal_scope.divergent_reservations = divergent_reservations

                    modal_scope.redirect = function (reservation_id) {
                      var url = $state.href('app.place.reservations.reservation', {
                        'place_id': place.id,
                        'reservation_id': reservation_id
                      }, {absolute: true});

                      return url;
                    }

                    modal_scope.modal = $uibModal.open({
                      templateUrl: require('./divergent_reservations.html'),
                      scope: modal_scope,
                      backdrop: 'static'
                    });
                  })
                  /*.then(function () {
                      //modal_scope.modal.close();
                      $state.go($state.current.name, $state.params, { reload: true });
                  })*/
                  .catch(function (err) {
                    // Temporarily silent (2018-10-15)
                    modal_scope.modal.close();
                    if (err.status === 422 && err.data && err.data.errors === 'airbnb_ko') {
                      scope.place.airbnb_connection = err.data.airbnb_connection
                      scope.place.instant_booking = err.data.instant_booking

                      modal_scope.modal = $uibModal.open({
                        templateUrl: require('./airbnb_error.html'),
                        scope: modal_scope,
                        backdrop: 'static'
                      });
                      return
                    }
                    if (err.status === 422) {
                      console.error('Invalid airbnb_link');
                      modal_scope.modal.close();
                    }
                  });
              };

              modal_scope.maids_search = function (value) {
                return Maid.search(value)
                  .then(function (places) {
                    return places;
                  });
              };
            };

            scope.icalImport = function (place) {
              Place.ical_import(place.id)
                .then(function (response) {
                  var title, body;
                  if (response.error) {
                    title = 'Error';
                    body = response.error;
                  } else {
                    title = 'Success';
                    body = response.message;
                  }
                  Modal.inform(title, body);

                  Place.availabilities(place.id)
                    .then(function (availability) {
                      vm.periods = availability.periods;
                      vm.reservations = availability.reservations;
                      vm.availability_main = place.availability;
                    });
                })
                .catch(function (error) {
                  console.error(error);

                  var title = 'An error occurred';
                  var body = 'Please help us improve the service by contacting us at '
                    + '<b><a href="mailto:' + configs.contact_email + '"> '
                    + configs.contact_email + '</a></b>';

                  Modal.inform(title, body);
                })
            }
          }
        };
      }])

  .constant('placeproperties', {
    'EMPTY': {'id': true, 'label': 'vacant', 'class': 'default'},
    'OCCUPIED': {'id': false, 'label': 'occupied', 'class': 'info'}
  })

  .directive('placeLabel',
    ['placeproperties',
      function (placeproperties) {
        return {
          restrict: 'A',
          scope: {placeLabel: '='},
          link: function (scope) {
            scope.states = placeproperties;
          },
          template: '<span ng-repeat="state in states"' +
          'class="label label-{{ state.class }}"' +
          'ng-show="placeLabel == state.id">  {{ state.label }} </span>'
        };
      }])

  .constant('cleaningStates', {
    'PENDING': {'id': 0, 'label': 'pending', 'class': 'default'},
    'DONE': {'id': 1, 'label': 'done', 'class': 'info'}
  })

  .directive('cleaningLabel',
    ['cleaningStates',
      function (cleaningStates) {
        return {
          restrict: 'A',
          scope: {cleaningLabel: '='},
          link: function (scope) {
            scope.states = cleaningStates;
          },
          template: '<span ng-repeat="state in states"' +
          'class="label label-{{ state.class }}"' +
          'ng-show="cleaningLabel == state.id">  {{ state.label }} </span>'
        };
      }]);
