{
    "answers": [
        {
            "id": 0,
            "title": "Apt Available",
            "content": "Hi, \n\nThank you for your inquiry. \n\nThe apartment is available for your stay and we would be more than happy to welcome you. \nLet us know if we can help you in any way, \n\nMarc"
        },
        {
            "id": 1,
            "title": "Apt Available - FR",
            "content": "Bonjour, \n\nMerci pour votre demande. \n\nL'appartement est disponible pour votre séjour et nous serions plus qu'heureux de vous y accueillir. \nFaites-nous savoir si nous pouvons vous aider de quelque manière que ce soit, \n\nMarc Hazan et l'équipe Keys'n'Fly"
        },
        {
            "id": 2,
            "title": "Arrival mail has been sent",
            "content": "Hi, \n\nWe have sent you an email with all the informations you need for your arrival XXX days ago. \nPlease check your email and spams. \n\nThe title of the email is: Keys'n'Fly - Arrival informations - Reservation Code \nBest regards,\nThe Keys'n'Fly Team"
        },
        {
            "id": 3,
            "title": "New Inquiry",
            "content": "Hi, \n\nThank you for your inquiry. \nPlease let me check the availability and I will get back to you as soon as possible. \n\nBest regards, \nMarc"
        },
        {
            "id": 4,
            "title": "Other property available",
            "content": "Hi, \n\nThank you again for your inquiry. \nUnfortunately, this listing won't be available for your stay. \n\nIf you are interested, we do have others properties available for the same period. \nYou can find them under our airbnb profile. \n\nPlease let me know if I can be of any help or if you found something that would suit you as well. \n\nBest regards, \nMarc"
        }
    ]
}