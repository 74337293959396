angular.module('dashboard', [])

.factory('DashboardStatistics', function () {
    var metadata = {
        'avg_duration': {
            'label': "Average duration",
            'unit': 'night(s)'
        },
        'occupancy_rate': {
            'label': "Occupancy rate",
            'unit': '%'
        },
        'avg_night_price': {
            'label': "Average night price",
            'unit': 'CHF'
        },
        'income': {
            'label': "Income",
            'unit': 'CHF'
        },
        'nights': {
            'label': "Total reserved",
            'unit': 'night(s)'
        },
        'commission': {
            'label': "Commission",
            'unit': 'CHF'
        },
        'guests': {
            'label': "Total guest",
            'unit': 'guest(s)'
        },
        'revpar': {
            'label': "RevPAR",
            'unit': 'CHF'
        }
    };

    return {
        compute: function(data) {
            var stats = _.keys(data.overall);
            var overall = _.map(data.overall, function(value, stat_name) {
                return {
                    label: metadata[stat_name].label,
                    unit: metadata[stat_name].unit,
                    value: value
                }
            });

            var sorted_periods = _.pairs(data.period).sort(function(a, b) {
                const dateA = a[0].split('/'); // ['mm', 'yyyy']
                const dateB = b[0].split('/');
                if (dateA[1] !== dateB[1])
                    return dateA[1] - dateB[1]; // year diff
                return dateA[0] - dateB[0]; // month diff
            });

            var periods = [];
            var history = [];
            _.each(sorted_periods, function (pair) {
                const period_name = pair[0];
                const period_stats = pair[1];
                _.each(stats, function (stat_name, i) {
                    // initialize container
                    if (!history[i]) {
                        history.push({
                            name: stat_name,
                            label: metadata[stat_name].label,
                            unit: metadata[stat_name].unit,
                            series: [metadata[stat_name].unit],
                            values: [],
                            data: [[]]
                        });
                    }
                    // Fill container
                    history[i].values.push(period_stats[stat_name]);
                    history[i].data[0].push(period_stats[stat_name]);
                });
                periods.push(period_name);
            });

            return {overall: overall, history: history, periods: periods};
        }
    };
})

.controller('Dashboard',
    ['$state', 'account', 'Place', 'DashboardStatistics',
    function Dashboard ($state, account, Place, DashboardStatistics) {
        var vm = this;
        vm.account = account;
        vm.place_id = $state.params.place_id;

        Place.statistics(vm.place_id).then(function(data) {
            const statistics = DashboardStatistics.compute(data);

            vm.periods = statistics.periods;
            vm.overall = statistics.overall;
            vm.history = statistics.history

            vm.selected_history = vm.history[0];

            vm.select_history = function (index) {
                vm.selected_history = vm.history[index];
            }

        });
    }]
);
