angular.module('notification')

.controller('Notifications',
    ['$stateParams', 'Notification', 'Place', '$scope', '$uibModal', '$timeout', 'account', 'Admin',
    function ($stateParams, Notification, Place, $scope, $uibModal, $timeout, account, Admin) {
    var vm = this;
    var doneCount = 0;
    vm.allDone = false;
    vm.account = account;

    vm.params = {
        page : 1,
        per_page : 20,
        mine : true,
    };

    vm.selectedCount = 0

    vm.toggleSelected = function (notification) {
        notification.selected = !notification.selected;
        vm.selectedCount += notification.selected ? 1 : -1;
    }

    vm.toggleAllSelected = function () {
        var selected
        if (vm.selectedCount === vm.notifications.length) {
            vm.selectedCount = 0;
            selected = false;
        } else {
            vm.selectedCount = vm.notifications.length;
            selected = true;
        }
        vm.notifications.forEach(function (n) { n.selected = selected })
    }

    vm.page_change = function() {
        Notification.all(vm.params)
            .then(function(notifications) {
                vm.notifications = notifications;
                doneCount = vm.notifications
                    .filter(function (n) { return n.done === true })
                    .length;
                vm.allDone = vm.notifications.length === doneCount;
            });
    };
    vm.page_change();

    $scope.$watchGroup(['vm.params.q', 'vm.params.mine'], function (qnew, qold) {
        if (qnew === qold)
            return;

        vm.params.page = 1;
        vm.page_change();
    });

    vm.markAllDone = function () {
        var idList = vm.notifications.map(function (n) { return n.id })
        Notification.done(idList)
            .then(function () {
                vm.notifications.forEach(function (n) { n.done = true });
                doneCount = idList.length;
                vm.allDone = vm.notifications.length === doneCount;
            });
    }

    vm.toggleDone = function (notification) {
        var endpoint = notification.done ? 'undone' : 'done';

        Notification[endpoint](notification.id)
            .then(function () {
                notification.done = !notification.done;
                doneCount += notification.done ? 1 : -1;
                vm.allDone = vm.notifications.length === doneCount;

                vm.success = true;

                $timeout(function() {
                    vm.success = false;
                }, 1000);
            });
    };

    vm.modal = function (notification) {
        var modal_scope = $scope.$new(false);

        modal_scope.places_search = function (value) {
            return Place.search(value)
                        .then(function (places) { return places; });
        };

        modal_scope.place_set = function (form) {
            form.place.$setValidity('set', true);
        };

        modal_scope.action = notification ? 'Edit' : 'Add';
        modal_scope.notification = notification || {};

        if (!notification)
            modal_scope.notification.responsible = vm.account;

        Admin.all()
            .then(function(admins) {
                modal_scope.users = admins;
            });

        modal_scope.modal = $uibModal.open({
            templateUrl : require('./modal.html'),
            scope : modal_scope,
            backdrop: 'static'
        });

        modal_scope.valid = function (form, notification) {
            form.$setSubmitted();

            if (!form.$valid)
                return;

            if (!notification.place || typeof notification.place === 'string')
                delete notification.place;

            if (notification.id) {
                notification.put()
                    .then(function (notification) {
                    for (var i = 0; i < vm.notifications.length; i++)
                        if (vm.notifications[i].id == notification.id)
                            vm.notifications[i] = notification;

                    modal_scope.modal.close();
                });
            } else
                Notification.add(notification)
                    .then(function (notification) {
                        vm.notifications.push(notification);
                        modal_scope.modal.close();
                    });

        };
    };
}]);
