angular.module('cleaning')
  .constant('missionStatus', {
    'PENDING': {'id': 0, 'label': 'Pending', 'class': 'info'},
    'DONE': {'id': 1, 'label': 'Done', 'class': 'success'},
    'CANCELLED': {'id': 2, 'label': 'Cancelled', 'class': 'default'},
  })

  .controller('Missions',
    ['$state', 'Maid', 'Mission', 'Report', 'Modal', 'actions', 'missionStatus', '$uibModal', '$q', '$scope', 'Place', '$window',
    function ($state, Maid, Mission, Report, Modal, actions, missionStatus, $uibModal, $q, $scope, Place, $window) {
    var vm = this;

    vm.params = {
      place_id: $state.params.place_id,
      action: null,
      search: null,
      page: 1,
      per_page: 20
    }
    vm.actions = actions;
    vm.maids = [];
    vm.missions = {};
    vm.missionsInterval = [];
    vm.missionsIntervalDayjs = []
    vm.filters = {
      date: new Date(),
      maid_id: null,
      code: ''
    }
    vm.missionStatus = missionStatus;
    vm.hours = []
    vm.mobile = false
    vm.showFilters = false

    vm.init = function () {
      Maid.list().then(function (d) {
        vm.maids = d
      })
      for(var i = 0; i < 24; i++){
        var hour = `${i}:00`
        if(i < 10){
          hour = `0${hour}`
        }
        vm.hours.push(hour)
      }

      $window.matchMedia('screen and (min-width: 768px)').addListener(function(e) {
        vm.mobile = !e.matches
        $scope.$apply()
      })

      vm.mobile = $window.innerWidth < 768

    };

    vm.page_change = function () {
        vm.missionsInterval = []
        vm.missionsIntervalDayjs = []
        vm.missions = {}
        const filterDate = vm.filters.code === '' ? vm.filters.date : dayjs()
        var dateStart = filterDate;

        Mission.all({
                date: dayjs(filterDate).format('YYYY-MM-DD'),
                maid_id: vm.filters.maid_id,
                code: vm.filters.code
            })
            .then(d => {
                if (vm.filters.code) {
                    dateStart = d[0].available_at
                    const date = dayjs(dateStart).add(0, 'day')
                    vm.missionsInterval.push(date.format('DD/MM/YYYY'))
                    vm.missionsIntervalDayjs.push(date)
                } else {

                    for (let i = 0; i < 7; i++) {
                        const date = dayjs(dateStart).add(i, 'day')
                        vm.missionsInterval.push(date.format('DD/MM/YYYY'))
                        vm.missionsIntervalDayjs.push(date)
                    }
                }

                for(let i = 0; i < vm.missionsInterval.length; i++) {
                    vm.missions[vm.missionsInterval[i]] = d.filter(m => {
                        const day = dayjs(vm.missionsIntervalDayjs[i])
                        return dayjs(m.available_at).isSame(day, 'day')
                    });
                }
            })
    };

    vm.date_change = function () {
      vm.page_change()
    };

    vm.maids_search = function (value) {
      return Maid.search(value)
        .then(function (places) {
          return places;
        });
    };

    vm.places_search = function (value) {
      return Place.search(value)
        .then(function (places) { return places; });
    };

    vm.mission_add = function() {
      var deferred = $q.defer();

      var modal_scope = $scope.$new();
      modal_scope.mission = {}
      modal_scope.hours = vm.hours
      modal_scope.maids_search = vm.maids_search;
      modal_scope.places_search = vm.places_search;

      modal_scope.modal = $uibModal.open({
        templateUrl: require('./mission-form.modal.html'),
        scope: modal_scope
      });

      modal_scope.place_changed = function(value) {
        modal_scope.mission['maid'] = value.maid
        modal_scope.mission['duration'] = value.maid_duration
        modal_scope.mission['access_informations'] = value.maid_access_informations
        modal_scope.mission['additional_informations'] = value.maid_additional_informations
      };

      modal_scope.valid = function (form) {
        form.$setSubmitted();

        if (!form.$valid)
          return;

        var hour_split = modal_scope.mission.hour.split(':')
        modal_scope.mission.available_at.setHours(hour_split[0], hour_split[1])
        Mission.add(modal_scope.mission)
          .then(function() {
            vm.page_change()
            modal_scope.modal.close();
          })
      };

      return deferred.promise;
    };

    vm.mission_update = function(mission) {
      var modal_scope = $scope.$new();
      modal_scope.mission = Object.assign({}, mission, {})

      modal_scope.mission['available_at'] = dayjs(mission.available_at).toDate()
      modal_scope.mission['hour'] = dayjs(mission.available_at).format('HH:mm')
      modal_scope.hours = vm.hours

      modal_scope.maids_search = vm.maids_search;

      modal_scope.places_search = vm.places_search;

      modal_scope.modal = $uibModal.open({
        templateUrl: require('./mission-form.modal.html'),
        scope: modal_scope
      });

      modal_scope.place_changed = function(value) {
        modal_scope.mission['duration'] = value.maid_duration
        modal_scope.mission['access_informations'] = value.maid_access_informations
        modal_scope.mission['additional_informations'] = value.maid_additional_informations
      };

      modal_scope.valid = function (form) {
        form.$setSubmitted();

        if (!form.$valid)
          return;

        var hour_split = modal_scope.mission.hour.split(':')

        modal_scope.mission.available_at.setHours(hour_split[0], hour_split[1])
        Mission.update(modal_scope.mission).then(function() {
          vm.page_change()
          modal_scope.modal.close();
        })
      };
    };

    vm.mission_cancel = function (id) {
      Modal.confirm('Cancel the mission', 'The mission will be set as cancelled').then(() => {

        Mission.delete(id).then(() => {
          Object.values(vm.missions).forEach(function(missions){
            missions.forEach(function(mission) {
              if(mission.id === id){
                mission['status'] = missionStatus.CANCELLED.id
                return
              }
            })
          })
        })
      })
    };

    vm.mission_detail = function (mission) {
      var modal_scope = $scope.$new();
      modal_scope.mission = mission;

      modal_scope.modal = $uibModal.open({
        templateUrl: require('./mission-form.modal.html'),
        scope: modal_scope
      });

      modal_scope.valid = function () {
        deferred.resolve(modal_scope.code.value);
        modal_scope.modal.close();
      };
    }

    vm.mission_report = function (mission) {
      var modal_scope = $scope.$new();
        modal_scope.mission = mission;
        modal_scope.report = mission.report ? mission.report : {};


        modal_scope.modal = $uibModal.open({
            templateUrl: require('../maid/report.modal.html'),
            scope: modal_scope,
            backdrop: 'static'
        });

        modal_scope.upload_callback = function (file, callback) {
            var datas = new FormData();
            datas.append('file', file);

            Report.upload(datas)
                .then(function (response) {
                  if(response.result === 'ok'){
                    if (!modal_scope.report.photos)
                      modal_scope.report.photos = [];

                    modal_scope.report.photos.push({
                      id : response.id,
                      url : response.url
                    });
                  }
                  callback();
                }, function () { callback(); });
        };

        modal_scope.delete_photo = function(photo) {
          Report.delete_photo(photo.id).then(function() {
            modal_scope.report.photos = modal_scope.report.photos.filter(p => p.id !== photo.id)
          })
        }

        modal_scope.valid = function (form, report) {
            form.$setSubmitted();

            if (!form.$valid)
                return;

            Mission.set(mission.id, report)
                .then(function () {
                    mission.status = missionStatus.DONE.id;
                    modal_scope.modal.close();
                })
        }
    };

    vm.init();
    vm.page_change();
  }])

  .constant('reportStatus', {
    'PENDING': {'id': 0, 'label': 'Pending', 'class': 'default'},
    'REQUESTED': {'id': 1, 'label': 'Requested', 'class': 'info'},
    'DONE': {'id': 2, 'label': 'Done', 'class': 'success'},
  })

  .controller('Reports',
    ['$state', 'Report', 'actions', 'Modal', 'reportStatus', '$scope', '$uibModal', '$window',
    function ($state, Report, actions, Modal, reportStatus, $scope, $uibModal, $window) {
    var vm = this;

    vm.params = {
      page: 1,
      per_page: 50
    }

    vm.actions = actions;
    vm.requests = [];
    vm.reportStatus = reportStatus;
    vm.mobile = false;

    $window.matchMedia('screen and (min-width: 768px)').addListener(function(e) {
      vm.mobile = !e.matches
      $scope.$apply()
    })

    vm.mobile = $window.innerWidth < 768

    vm.template = function() {
        if(vm.mobile){
            return require('./missions.mobile.html')
        }
        return require('./missions.desktop.html')
    }

    vm.page_change = function () {
      Report.all(vm.params)
        .then(function (d) {
            vm.reports = d
        })
    }

    vm.update = function (report) {
      var modal_scope = $scope.$new();
      modal_scope.report = report;
      modal_scope.status = reportStatus;

      modal_scope.modal = $uibModal.open({
          templateUrl: require('./report.status.modal.html'),
          scope: modal_scope,
          backdrop: 'static'
      });

      modal_scope.upload_callback = function (file, callback) {
          var datas = new FormData();
          datas.append('file', file);

          Report.upload(datas)
              .then(function (response) {
                  if (!modal_scope.report.photos)
                      modal_scope.report.photos = [];

                  modal_scope.report.photos.push({
                      id : response.id,
                      url : response.url
                  });
                  callback();
              }, function () { callback(); });
      };

      modal_scope.delete_photo = function(photo) {
        Report.delete_photo(photo.id).then(function() {
          modal_scope.report.photos = modal_scope.report.photos.filter(p => p.id !== photo.id)
        })
      }

      modal_scope.valid = function (form, report) {
          form.$setSubmitted();

          if (!form.$valid)
              return;

          Report.set(report.id, report)
              .then(function () {
                  modal_scope.modal.close();
              })
      }
    }

    vm.page_change();
  }]);
