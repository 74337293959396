var app = angular.module('knf-components', [])

app.factory('Modal',
    ['$rootScope', '$q', '$uibModal',
    function($rootScope, $q, $uibModal) {
    return {
        confirm: function(title, body) {
            var deferred = $q.defer();

            var modal_scope = $rootScope.$new();
            modal_scope.title = title;
            modal_scope.body = body;

            modal_scope.modal = $uibModal.open({
                templateUrl : require('./modal.confirm.html'),
                scope : modal_scope
            });

            modal_scope.cancel = function() {
                deferred.reject();
                modal_scope.modal.close();
            };

            modal_scope.confirm = function() {
                deferred.resolve();
                modal_scope.modal.close();
            };

            return deferred.promise;
        },

        inform: function(title, body) {
            var deferred = $q.defer();

            var modal_scope = $rootScope.$new();
            modal_scope.title = title;
            modal_scope.body = body;

            modal_scope.modal = $uibModal.open({
                templateUrl : require('./modal.inform.html'),
                scope : modal_scope
            });

            modal_scope.close = function() {
                deferred.resolve();
                modal_scope.modal.close();
            };

            return deferred.promise;
        },
        search_reservation: function(){
            var deferred = $q.defer();

            var modal_scope = $rootScope.$new();
            modal_scope.code = { value: '' };

            modal_scope.modal = $uibModal.open({
              templateUrl : require('./reservation/search.modal.html'),
              scope : modal_scope
            });

            modal_scope.valid = function() {
              deferred.resolve(modal_scope.code.value);
              modal_scope.modal.close();
            };

            return deferred.promise;
        },
        // mission_report: function (mission) {
        //   var deferred = $q.defer();

        //   var modal_scope = $rootScope.$new();
        //   modal_scope.mission = mission;

        //   modal_scope.modal = $uibModal.open({
        //     templateUrl: 'app/cleaning/mission-report.modal.html',
        //     scope: modal_scope
        //   });

        //   modal_scope.valid = function () {
        //     deferred.resolve(modal_scope.code.value);
        //     modal_scope.modal.close();
        //   };

        //   return deferred.promise;
        // }
    };
}]);

app.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.ngEnter);
                });

                event.preventDefault();
            }
        });
    };
});

app.filter('nl2br', ['$sce', function ($sce) {
    return function (text) {
        return text ? $sce.trustAsHtml(text.replace(/\n/g, '<br/>')) : '';
    };
}]);

app.factory('Availability', function () {
    /**
     * Make the Union of a selected period with a list of Periods.
     * Return an array of Period.
     * Period: { from_date: Date, to_date: Date }
     * @param {Period} selected_period
     * @param {Period[]} periods
     */
    function AddPeriods(selected_period, periods) {
        // new               |--------------------|
        // old   |---|  |---|   |---|     |---| |----|   |---|
        // (+)   |---|  |----------------------------|   |---|
        var new_periods = [];
        var adjacent_start = new Date(selected_period.from_date);
        var adjacent_end = new Date(selected_period.to_date);
        adjacent_start.setDate(adjacent_start.getDate() - 1);
        adjacent_end.setDate(adjacent_end.getDate() + 1);
        for (var period of periods) {
            // period is bigger than selected_period, selected_period won't have any impact
            // (this case discards previous loops job)
            if (period.from_date <= selected_period.from_date && period.to_date >= selected_period.to_date) {
                return periods;
            }
            // period is inside selected_period, and need to be removed
            if (period.from_date >= selected_period.from_date && period.to_date <= selected_period.to_date) {
                continue;
            }
            // period is not colliding and not adjacent to selected_period
            if (period.to_date < adjacent_start || period.from_date > adjacent_end) {
                new_periods.push(period);
                continue;
            }
            // with previous if statements, we can only be here if either
            // end or start are inside selected_period, which is an extend case.
            if (period.from_date < selected_period.from_date) {
                // extend selected_period's start
                selected_period.from_date = period.from_date;
            }
            if (period.to_date > selected_period.to_date) {
                // extend selected_period's end
                selected_period.to_date = period.to_date;
            }
        }
        // Finally add the possibly modified selected_period
        new_periods.push(selected_period);
        return new_periods;
    }

    /**
     * Remove or reduce periods overlapping with selected_period
     * Return an array of Period.
     * Period: { from_date: Date, to_date: Date }
     * @param {Period} selected_period
     * @param {Period[]} periods
     */
    function SubstractPeriods(selected_period, periods) {
        // new             |------------------------|
        // old   |---| |--|       |---|     |---| |----|   |---|
        // (-)   |---| |--|                          |-|   |---|
        var new_periods = [];
        for (var period of periods) {
            // period is inside selected_period, and need to be removed
            if (period.from_date >= selected_period.from_date && period.to_date <= selected_period.to_date) {
                continue;
            }
            // period is not colliding with selected_period, keep it
            if (period.to_date < selected_period.from_date || period.from_date > selected_period.to_date) {
                new_periods.push(period);
                continue;
            }
            // the following 2 if statements can both execute in a single loop
            // when period is bigger than selected_period, which creates 2 new periods
            if (period.from_date < selected_period.from_date) {
                // reduce period's end
                var reduced_end = new Date(selected_period.from_date);
                reduced_end.setDate(reduced_end.getDate() - 1);
                new_periods.push({from_date: period.from_date, to_date: reduced_end});
            }
            if (period.to_date > selected_period.to_date) {
                //reduce period's start
                var reduced_start = new Date(selected_period.to_date);
                reduced_start.setDate(reduced_start.getDate() + 1);
                new_periods.push({from_date: reduced_start, to_date: period.to_date});
            }
        }
        return new_periods;
    }

    return {
        resolveIntervals: function (selected_period, periods, reservations, general_availability) {
            // periods define intervals of the opposite value of general_availability
            // selected_period.availability is the new value to apply
            // different ==> add periods
            // same ==> remove periods
            var new_periods = general_availability !== selected_period.availability
                ? AddPeriods(selected_period, periods)
                : SubstractPeriods(selected_period, periods);

            // Reservations are unavailable by definition but can never overlap with periods,
            // even in the counter intuitive case where default availability is true (available)
            // When adding periods, substract for each reservation to erase any possible overlap.
            if (general_availability !== selected_period.availability) {
                for (var rsrv of reservations) {
                    var to_date = new Date(rsrv.to_date.getTime())
                    to_date.setDate(to_date.getDate() - 1)
                    new_periods = SubstractPeriods({from_date: rsrv.from_date, to_date}, new_periods)
                }
            }

            return new_periods;
        }
    }
});

app.directive('availabilityCalendar',
    ['Place', 'Availability', function (Place, Availability) {
    return {
        scope: {
            'account': '=',
            'periods': '=',
            'period': '=',
            'reservations': '=',
            'availability': '=',
            'reservationDisplay': '&',
            'periodMax': '=',
            'save': '='
        },
        templateUrl: require('./place/calendar.html'),
        link: function(scope, elm, attrs, controller) {
            var interval = {start: new Date(), end: false};
            interval.start.setDate(1);
            interval.start.setHours(0, 0, 0, 0);
            interval.end = new Date(interval.start.getFullYear(), interval.start.getMonth() + 1, 0);
            scope.date = interval.start;
            scope.selecting = false;

            var general_availability;
            var reservations = scope.reservations;
            var periods = angular.copy(scope.periods);
            var selection = {
                availability: null,
                start: false,
                end: false
            }

            function refresh () {
                var today = new Date();
                var weeks = [[], [], [], [], [], []];
                var week = 0;

                if (scope.periodMax) {
                    var booking_max = new Date();
                    booking_max.setMonth(booking_max.getMonth() + parseInt(scope.periodMax));
                }

                var current_date = new Date(interval.start.getFullYear(), interval.start.getMonth(), 1);
                while (current_date.getDay() != 1) current_date.setDate(current_date.getDate() - 1);
                var first_day = current_date.getDay();

                for (var i = 0; i < weeks.length * 7; i++) {
                    var day = current_date.getDate();
                    var precise_day = current_date.getTime();
                    var month = current_date.getMonth();

                    var classes = [];
                    var day_reservations = [];
                    var disabled = false;
                    var available = general_availability;

                    var checkin = false;
                    var checkin_bis = false;
                    var checkout = false;

                    if (current_date.getMonth() != interval.start.getMonth())
                        disabled = true;

                    angular.forEach(reservations, function (r) {
                        var from = new Date(r.from_date)
                        var to = new Date(r.to_date)

                        if (current_date.getTime() >= from.getTime() && current_date.getTime() <= to.getTime()) {
                            if (current_date.getTime() == from.getTime()) {
                                r.start = from.getDate();
                                r.precise_start = from.getTime();
                                r.start_time = r.from_datetime.getHours() + ':00';
                            }

                            if (current_date.getTime() == to.getTime()) {
                                r.end = to.getDate();
                                r.precise_end = to.getTime();
                                r.end_time = r.to_datetime.getHours() + ':00';
                            }

                            day_reservations.push(r);
                        }
                    });

                    angular.forEach(periods, function (p) {
                        if (current_date >= p.from_date && current_date <= p.to_date) {
                            available = !available;
                            return;
                        }
                    });

                    if (day_reservations.length) {
                        if (day_reservations[0] !== undefined) {
                            checkin = day_reservations[0].precise_start === precise_day;
                            checkout = day_reservations[0].precise_end === precise_day;
                        }
                        if (day_reservations[1] !== undefined) {
                            checkin_bis = day_reservations[1].precise_start === precise_day
                        }
                    }

                    var d = {
                        date: day,
                        month: month,
                        reservations: day_reservations,
                        disabled: disabled,
                        available: available,
                        index: i,
                        checkin: checkin,
                        checkin_bis: checkin_bis,
                        checkout: checkout
                    }

                    current_date.setHours(0, 0, 0, 0);
                    today.setHours(0, 0, 0, 0);
                    if (current_date.getTime() < today.getTime())
                        d.past = true;

                    if (booking_max && current_date.getTime() > booking_max.getTime())
                        d.booking_unavailable = true;

                    if (selection.start && day >= selection.start &&
                        selection.end && day <= selection.end)
                        d.selected = true;

                    weeks[week].push(d);

                    if (!((i + 1) % 7))
                        week++;

                    current_date.setDate(day + 1);
                }

                scope.weeks = weeks;
            }

            scope.$watch('availability', function (oldval, newval) {
                if (newval === oldval)
                    return;

                general_availability = scope.availability;
                refresh();
            });

            scope.$watch('reservations', function () {
                reservations = scope.reservations;
                refresh();
            });

            scope.$watch('periods', function () {
                periods = angular.copy(scope.periods);
                refresh();
            });

            scope.$watch('period', function () {
                if (!scope.period)
                    return;

                scope.periods = Availability.resolveIntervals(scope.period, periods, reservations, general_availability);
                refresh();
            });

            scope.prev_month = function () {
                interval.start.setMonth(interval.start.getMonth() - 1);
                interval.start.setDate(1);
                interval.end = new Date(interval.start.getFullYear(), interval.start.getMonth() + 1, 0);
                refresh();
            };

            scope.next_month = function () {
                interval.start.setMonth(interval.start.getMonth() + 1);
                interval.start.setDate(1);
                interval.end = new Date(interval.start.getFullYear(), interval.start.getMonth() + 1, 0);
                refresh();
            };

            scope.selectable = function (day) {
                if(day.reservations.length && day.checkout){
                    return !day.disabled && !day.past
                }
                return !day.reservations.length && !day.disabled && !day.past
            }

            scope.selection_start = function (day) {
                if (!scope.selectable(day))
                    return;
                selection.start = day;
                selection.end = day;
                scope.weeks[day.index/7|0][day.index%7].selected = true;
                selection.availability = !day.available;
            };

            scope.reservation_display = function (rsrv) {
                scope.reservationDisplay({reservation: rsrv});
            };

            scope.over = function (day) {
                if (!selection.start ||
                    !scope.selectable(day) ||
                    (day.date === selection.start.date && day.date === selection.end.date)) {
                    return;
                }

                scope.selecting = true;
                selection.end = day;

                // stop selection before the end of a reservation (or after the start)
                angular.forEach(reservations, function (rsrv) {
                    var from = new Date(rsrv.from_date);
                    var to = new Date(rsrv.to_date);

                    if (from <= selection.start && selection.start <= to) {
                        selection.start = to.setDate(to.getDate() + 1)
                    }
                    if (from <= selection.to && selection.to <= to) {
                        selection.to = from.setDate(from.getDate() - 1)
                    }
                });
                // update selected attribute of all days
                var start = Math.min(selection.start.index, selection.end.index);
                var end = Math.max(selection.start.index, selection.end.index);
                for (var i = 0; i < scope.weeks.length*7; i++) {
                    scope.weeks[i/7|0][i%7].selected = (start <= i && i <= end);
                }
            };

            scope.selection_stop = function () {
                if (!selection.start || !selection.end)
                    return;
                scope.selecting = false;
                // invert from_*** and to_*** if needed
                var from_month, from_day, to_month, to_day;
                if (selection.start.index > selection.end.index) {
                    from_month = selection.end.month;
                    from_day = selection.end.date;
                    to_month = selection.start.month;
                    to_day = selection.start.date;
                } else {
                    from_month = selection.start.month;
                    from_day = selection.start.date;
                    to_month = selection.end.month;
                    to_day = selection.end.date;
                }
                // Update periods:
                var selected_period = {
                    from_date: new Date(interval.start.getFullYear(), from_month, from_day),
                    to_date: new Date(interval.start.getFullYear(), to_month, to_day),
                    availability: selection.availability
                }

                scope.periods = Availability.resolveIntervals(selected_period, periods, reservations, general_availability);
                // Reset selection:
                for (var i = selection.start.index; i <= selection.end.index; i++) {
                    scope.weeks[i/7|0][i%7].available = selection.availability;
                    scope.weeks[i/7|0][i%7].selected = false;
                }
                // Reset values:
                selection.availability = scope.availability;
                selection.start = false;
                selection.end = false;
            }

            scope.out = function () {
                scope.show_info = false;
                scope.selecting = false;
            };
        }};
}]);

app.directive('validateEmail', function() {
    var EMAIL_REGEXP = /^[a-z0-9]([\.\-\=\+_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;

    return {
        require: 'ngModel',
        link: function (scope, element, attrs, controller) {
            if (controller && controller.$validators.email) {
                controller.$validators.email = function (modelValue) {
                    return controller.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
                }

                controller.$validators.valid = function (modelValue) {
                    return controller.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
                }
            }
        }
    };
})

app.directive('numberPicker', function() {
    return {
        scope: {
            ngModel: '=',
            min: '@',
            max: '@',
            plural: '@',
            name: '@',
            form: '=',
            step: '=?',
            ngChange: '&',
            error: '=?'
        },
        templateUrl: require('./place/form-old/number.picker.html'),
        link : function(scope, element, attrs) {
            scope.step = scope.step || 1;
            scope.error = scope.error || 'incoherent value';
            scope.field = scope.form[scope.name];

            scope.decrement = function () {
                var v = parseFloat(scope.ngModel);

                if(v <= scope.min)
                    return;

                scope.ngModel = v - scope.step;
            };

            scope.increment = function () {
                var v = parseFloat(scope.ngModel);

                if(v >= scope.max)
                    return;

                scope.ngModel = v + scope.step;
            };

            scope.$watch('ngModel', function (newVal, oldVal) {
                if (scope.ngChange)
                    scope.ngChange();
            });
        }
    };
});

app.directive('decimal', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, controller) {
            var DECIMAL_REGEXP = /^[0-9]+((\.|,)[0-9]{1,2})?$/;

            if (controller) {
                controller.$formatters.push(function (val) {
                    return parseFloat(val);
                });

                controller.$validators.decimal = function (modelValue) {
                    return controller.$isEmpty(modelValue) || DECIMAL_REGEXP.test(modelValue);
                }

                controller.$validators.valid = function (modelValue) {
                    return controller.$isEmpty(modelValue) || DECIMAL_REGEXP.test(modelValue);
                }
            }

            // set value to null instead of empty string for backend purpose
            scope.$watch(attrs.ngModel, function (value, old) {
                if (value !== '')
                    return;

                controller.$setViewValue(null);
                controller.$render();
            }, true);
        }
    };
});

app.directive('convertToNumber', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return parseInt(val, 10);
            });
            ngModel.$formatters.push(function (val) {
                return '' + val;
            });
        }
    };
});

app.factory('Processing', ['$uibModal',
    function ($uibModal) {
        var modal = false;
        var views = [];

        return {
            open: function (view) {
                if (views.length > 0)
                    return;

                views.push(view);

                modal = $uibModal.open({
                    animation: false,
                    backdrop: 'static',
                    windowClass: 'loading-modal',
                    template: '<span class="glyphicon glyphicon-cog"></span>' + ' Chargement',
                    size: 'sm',
                });

            },

            close: function (view) {
                var index = views.indexOf(view);

                while (index != -1) {
                    views.splice(index, 1);
                    index = views.indexOf(view);
                }

                if (views.length == 0)
                    modal.close();
            },

            close_all: function () {
                views = [];
                modal.close();
            },
        };
}]);

app.filter('missionReference', [function () {
  return function (code, mission) {
    return (code) ? code : 'host-' + mission.id
  };
}]);

app.filter('currency', [function () {
  return function (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") + ' CHF';
  };
}]);

app.directive('resetPlaceValue', function(){
  return {
    scope: {
      placeValue: '=',
      ngModel: '='
    },
    require: 'ngModel',
    template:
      '<small class="reset-place-value" ng-if="placeValue !== ngModel">' +
        '<a ng-click="change_value()" style="color: red;">' +
          '<i class="glyphicon glyphicon-refresh" title="Reset to place value"></i> ' +
        '</a>' +
      '</small>',
    link: function (scope, element, attrs, ngModel) {
      scope.change_value = function(){
        ngModel.$viewValue = scope.placeValue; //Set new view value
        ngModel.$commitViewValue(); //Commit a pending update to the $modelValue
      }
    }
  };
})

app.filter('capitalize', function() {
    return function(input) {
        return (angular.isString(input) && input.length > 0) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : input;
    }
});
