angular.module('activity', [])

.constant('actions', {
    0: "Creation",
    1: "Update",
    2: "Completion",
    3: "State",
    4: "Suppression"
})

.controller('Activity',
['$state', 'Place', 'actions',
function($state, Place, actions){
    var vm = this;

    vm.params = {
        place_id: $state.params.place_id,
        action: null,
        search: null,
        page: 1,
        per_page: 20
    }
    vm.actions = actions;
    vm.history = [];
    vm.page_change = function() {
        Place.activity(vm.params)
        .then(function(history) {
            vm.history = history;
            angular.forEach(vm.history, function(change) {
                change.action = actions[change.action];
            });
        })
    }
    vm.page_change();

}]);
