angular.module('maid')

  .filter('photoUrl',
    ['configs',
      function (configs) {

        return function (document) {
          const url = window.location.href
          const arr = url.split('/')
          return arr[0] + "//" + arr[2] + document;
        }
      }])

  .directive('fileChangePhoto',
    ['$http',
      function ($http) {
        return {
          restrict: 'A',
          scope: {
            callback: '&',
            doctype: '='
          },
          link: function (scope, element, attrs) {

            element.bind('change', function (event) {
              window.Compress.compressImageFile(event.target.files[0]).then(res => {
                const compressFile = new File([res], res.name, {type: res.contentType, lastModified: new Date()})
                scope.callback()(compressFile, function () {
                  element.val(null);
                });
              }).catch(err => {
                alert(err.toString())
              })
            });
          }
        };
      }]);
