angular.module('host')

.controller('Hosts',
    ['Host', '$scope', '$uibModal', '$timeout', 'Modal',
    function (Host, $scope, $uibModal, $timeout, Modal) {
    var vm = this;

    vm.params = {
        page : 1,
    };

    vm.page_change = function() {
        Host.all(vm.params)
            .then(function(hosts) {
                vm.hosts = hosts;
            });
    };

    vm.page_change();

    $scope.$watch('vm.params.q', function (qnew, qold) {
        if (qnew === qold)
            return;

        if (qnew) {
            vm.page_change();
        } else {
            vm.params.page = 1;
            vm.page_change();
        }
    });

    vm.application_fee_set = function (host_id, application_fee) {
        Host.application_fee(host_id, application_fee)
            .then(function() {
                vm.success = true;

                $timeout(function() {
                    vm.success = false;
                }, 1000);
            });
    };

    vm.bank_account = function (host) {
        var modal_scope = $scope.$new(false);

        modal_scope.host = host;

        modal_scope.modal = $uibModal.open({
            templateUrl : require('./inform.html'),
            scope : modal_scope,
            backdrop: 'static'
        });
    };

    vm.host_edit = function (host) {
        var modal_scope = $scope.$new(false);

        modal_scope.host = host;

        modal_scope.modal = $uibModal.open({
            templateUrl : require('./form.admin.html'),
            scope : modal_scope,
            backdrop: 'static'
        });

        modal_scope.save = function (form, host) {
            if (form.$invalid)
                return;

            Host.update(host)
                .then(function () {
                    modal_scope.modal.close();
                });
        };
    };

    vm.commentary = function (commentary) {
        Modal.inform('Commentary', commentary);
    };
}])

.controller('Profile',
    ['Restangular', 'account', '$timeout', 'Account', '$state',
    function (Restangular, account, $timeout, Account, $state) {
    var vm = this;

    vm.account = Restangular.copy(account);
    vm.billing_address = false;
    var fields = ['street', 'postal_code', 'city', 'country'];

    if (!vm.account.host.billing_address) {
        vm.account.host.address = {};
        vm.account.host.billing_address = {};
    } else
        for (var i = 0; i < fields.length; i++)
            if (vm.account.host.billing_address[fields[i]] != vm.account.host.address[fields[i]])
                vm.billing_address = true;

    vm.address_copy = function (clear) {
        if (vm.billing_address) {
            if (clear)
                vm.account.host.billing_address = {};
            return;
        }

        for (var i = 0; i < fields.length; i++)
            vm.account.host.billing_address[fields[i]] = vm.account.host.address[fields[i]];
    };

    vm.save = function (form, account) {
        form.$setSubmitted();

        if (form.$invalid)
            return;

        Account.profile_update(account)
            .then(function (account) {
                vm.account = account;
                vm.account.update(account);
                vm.success = true;

                $timeout(function() {
                    vm.success = false;

                    if (vm.account.host.places_count == 0
                        && vm.account.completed())
                        $state.go('app.pform.address');
                    else
                        $state.go('app.host', false, {reload: true});
                }, 500);
            });
    };
}])

/* Host places listing from the host pov */
.controller('HostPlaces',
    ['account', '$state', 'FormManager', '$q', '$scope', '$uibModalStack','$uibModal', 'Host',
    function (account, $state, FormManager, $q, $scope, $uibModalStack, $uibModal, Host) {
    var vm = this;

    vm.add_place = function () {
        var deferred = $q.defer();
        var modal_scope = $scope.$new();

        vm.place_name = ""
        modal_scope.modal = $uibModal.open({
            templateUrl: require('./add_place.modal.html'),
            scope: modal_scope,
        });

        modal_scope.create_place = function (place_name, create_airbnb) {
            $uibModalStack.dismissAll();

            Host.create_place(Number(account.host.id), Boolean(create_airbnb), place_name)
                .then(function (response) {
                    $state.go('app.pform.listing_details',{"place_id" : response.id, 'listing_id': response.listing_id });
                }).catch(function (response) {
                    console.log('An error occurs. Unable to create the place. Error: ', response)
            });


        }

        return deferred.promise;
    }

    Host.get(account.host.id)
        .then(function (host) {
            vm.owner = host.user.firstname + ' ' + host.user.lastname;
            vm.places = host.places;
        });
}])

/* Host places listing from the admin pov */
.controller('Properties',
    ['Host', '$stateParams', 'account', '$q', '$uibModal', '$scope', '$uibModalStack', '$state',
    function (Host, $stateParams, account, $q, $uibModal, $scope, $uibModalStack, $state) {
    var vm = this;

    vm.account = account;
    vm.host_id = $stateParams.host_id;

    vm.add_place = function () {
        console.log('add_place')
        var deferred = $q.defer();
        var modal_scope = $scope.$new();

        vm.place_name = ""
        modal_scope.modal = $uibModal.open({
            templateUrl: require('./add_place.modal.html'),
            scope: modal_scope,
        });

        modal_scope.create_place = function (place_name, create_airbnb) {
            $uibModalStack.dismissAll();

            Host.create_place(Number(vm.host_id), Boolean(create_airbnb), place_name)
                .then(function (response) {
                    $state.go('app.pform.listing_details',{"place_id" : response.id, 'listing_id': response.listing_id });
                }).catch(function (response) {
                    console.log('An error occurs. Unable to create the place. Error: ', response)
            });


        }

        return deferred.promise;
    }

    Host.get($stateParams.host_id)
        .then(function (host) {
            vm.owner = host.user.firstname + ' ' + host.user.lastname;
            vm.places = host.places;
        });
}]);
