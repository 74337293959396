angular.module('maid', [])

  .factory('MaidMission',
    ['$q', 'Restangular',
    function ($q, Restangular) {
        return {
            all: function (params) {
                return Restangular.all('/maids/missions').getList(params);
            }
        };
    }])

  .factory('MaidReport',
    ['$q', 'Restangular',
    function ($q, Restangular) {
        return {
            all: function () {
                return Restangular.all('/maids/reports').getList();
            }
        };
    }]);
