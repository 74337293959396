angular.module('cleaning', [])

  .factory('Maid',
    ['$q', 'Restangular',
      function ($q, Restangular) {

        return {
          all: function (params) {
            return Restangular.all('maids').getList(params);
          },
          list: function (params) {
            return Restangular.all('maids/list').getList();
          },
          delete: function(id){
            return Restangular.one('maids', id).remove()
          },
          get: function(id){
            return Restangular.one('maids', id).get()
          },
          update: function(maid){
            return Restangular.one('maids', maid.id).customPUT(maid);
          },
          add: function (maid) {
            return Restangular.all('maids').post(maid);
          },
          search: function (q) {
            return Restangular.all('maids').customGET('search', {'q' : q});
          },
        };
      }])

  .factory('Mission',
    ['$q', 'Restangular',
      function ($q, Restangular) {

        return {
          all: function (params) {
            return Restangular.all('missions').getList(params);
          },
          set: function (id, report) {
            return Restangular.one('missions', id).post('set', report)
          },
          add: function (params) {
            return Restangular.all('missions').post(params);
          },
          update: function(params) {
            return Restangular.one('missions', params.id).customPUT(params);
          },
          delete: function(id){
            return Restangular.one('missions', id).remove();
          }
        };
      }])

  .factory('Report',
    ['$q', 'Restangular',
    function ($q, Restangular) {
        return {
            all: function (params) {
                return Restangular.all('reports').getList(params);
            },
            set: function (id, params) {
                return Restangular.one('reports', id).post('set', params);
            },
            upload: function (datas) {
                return Restangular.one('reports', 'photos')
                          .withHttpConfig({transformRequest: angular.identity})
                          .customPOST(datas, '', undefined, {'Content-Type': undefined});
            },
            delete_photo: function (id) {
              return Restangular.one('reports', 'photos')
                .withHttpConfig({transformRequest: angular.identity})
                .customDELETE(id);
            },
            pending: function () {
                return Restangular.one('reports', 'pending').customGET()
            }
        };
    }]);
