angular.module('reservation', [])

.factory('Reservation',
    ['$q', 'Restangular',
    function ($q, Restangular) {

    var current_all;
    return {
        all: function (params) {
            if (current_all) current_all.resolve();
            current_all = $q.defer();
            return Restangular.all('reservations')
                        .withHttpConfig({timeout: current_all.promise})
                        .getList(params);
        },

        unverified: function () {
            return Restangular.all('reservations').customGET('unverified');
        },

        add: function(reservation) {
            return Restangular.all('reservations').post(reservation);
        },

        get: function (reservation_id) {
            return Restangular.one('reservations', reservation_id).get();
        },

        last: function (place_id) {
            return Restangular.all('reservations').one('last', place_id).get();
        },

        remove: function (reservation_id, params) {
          return Restangular.one('reservations', reservation_id).customDELETE('', params);
        },

        search_by_code: function(code){
          return Restangular.all('reservations').one('code', code).get();
        },

        send_guest_confirm_mail: function(reservationId) {
          return Restangular
            .all('reservations')
            .customPOST({}, `${reservationId}/guest-confirm-mail`)
        },

        accept_or_deny: function(code, attemptAction) {
            return Restangular
                .all('reservations')
                .customPUT({attempt_action: attemptAction}, `${code}/accept_or_deny`)
        }
    };
}])

.factory('Email',
    ['Restangular',
    function (Restangular) {
    return {
        all: function (params) {
            return Restangular.all('emails').getList();
        },

        updates: function(emails) {
            return Restangular.all('emails').post({'emails' : emails});
        },

        update: function(email) {
            return Restangular.one('emails', email.id).customPUT(email);
        },

        media_add: function (datas) {
            return Restangular.all('emails')
                    .withHttpConfig({transformRequest: angular.identity})
                    .customPOST(datas, 'attachments', undefined, {'Content-Type': undefined});
        },
    };
}])

.factory('Payment',
    ['Restangular',
    function (Restangular) {
    return {
        all: function (params) {
            return Restangular.all('payments').getList(params);
        },

        done: function (payment_id) {
            return Restangular.one('payments', payment_id).customGET('done');
        },
    };
}]);
