angular.module('maid')

  .controller('MaidReports',
    ['$state', 'MaidReport', 'Modal', 'actions', 'missionStatus', '$uibModal', '$q', '$scope', 'reportStatus', '$window',
    function ($state, MaidReport, Modal, actions, missionStatus, $uibModal, $q, $scope, reportStatus, $window) {
    var vm = this;

    vm.params = {
      place_id: $state.params.place_id,
      action: null,
      search: null,
      page: 1,
      per_page: 20
    }
    vm.actions = actions;
    vm.requests = [];
    vm.reportStatus = reportStatus;
    vm.mobile = false;

    $window.matchMedia('screen and (min-width: 768px)').addListener(function(e) {
      vm.mobile = !e.matches
      $scope.$apply()
    })

    vm.mobile = $window.innerWidth < 768

    vm.page_change = function () {
      MaidReport.all()
        .then(function (d) {
            vm.reports = d
        })
    }

    vm.page_change();

  }]);
