angular.module('place')

.constant('placeStates', {
    'DRAFT' : {'id' : 0, 'label' : 'Draft', 'class' : 'default'},
    'INACTIVE' : {'id' : 1, 'label' : 'Inactive', 'class' : 'primary'},
    'ACTIVE' : {'id' : 2, 'label' : 'Active', 'class' : 'success'},
})

.constant('formOnly', {
    'address':      ['address'],
    'space':        ['occupancy_max','bedrooms','bathrooms','beds','beds_single','beds_double','beds_bunk','beds_king','beds_queen','beds_sofa','beds_couch'],
    'amenities':    ['amenities','amenities_other'],
    'photos':       ['pictures'],
    'description':  ['description','keys','keys_boxcode','keys_name','keys_phone','keys_email','access_informations','parking','parking_description','space_access','comments'],
    'rules':        ['events','invite','smoking','pets','accessibility','babies','youngs','elders','rules'],
    'management':   ['approval','last_minute'],
    'timings':      ['checkin','checkout','booking_period_max'],
    'duration':     ['nights_min','nights_max'],
    'pricing':      ['price','currency'],
    'discounts':    ['discount_weekly','discount_monthly','deposit'],
    'cleanings':    ['cleaning_management','maid_name','maid_phone','maid_email','maid_price','linens_towels','machines','machines_location','machines_program'],
    'interlocutor': ['emergency_contact','emergency_name','emergency_phone','emergency_email'],
})

.directive('placeState',
    ['placeStates',
    function (placeStates) {
    return {
        restrict: 'A',
        scope: { placeState: '=' },
        link: function (scope) { scope.states = placeStates; },
        template: '<span ng-repeat="state in states" '
                + 'class="label label-{{ state.class }}" '
                + 'ng-show="placeState == state.id">{{ state.label }}</span>'
    };
}])

.factory('FormManager',
    ['$state',
    function ($state) {

    var view_base = 'app.pform.';
    var view_summary = 'app.pform-edit';

    var step_current = false;
    var view_current = false;

    var params = {};

    var steps_views = [
        [
            view_base + 'address',
            view_base + 'space',
            view_base + 'amenities',
        ],
        [
            view_base + 'photos',
            view_base + 'description',
        ],
        [
            view_base + 'rules',
            view_base + 'management',
            view_base + 'timings',
            view_base + 'duration',
            view_base + 'pricing',
            view_base + 'discounts',
            view_base + 'cleanings',
            view_base + 'interlocutor',
        ]
    ];
    var steps_completion = [[], [], []];

    var target = function (direction) {
        if (!direction)
            return view_summary;

        view_current = direction === 'next' ? view_current + 1 : view_current - 1;

        var view_name = steps_views[step_current][view_current];
        if (view_name)
            return view_name;
        else
            return view_summary;
    };

    return {
        step_current_get : function () {
            return step_current;
        },

        step_completion_get : function (step) {
            var s = step !== undefined ? step : step_current;

            if (s === false)
                return;

            var step_completion = steps_completion[s];
            var completed = 0;

            for (var i = 0; i < step_completion.length; i++)
                if (step_completion[i])
                    completed += 1;

            return completed / step_completion.length * 100;
        },

        completion_get : function () {
            steps_completion[step_current][view_current] = true;

            var states = _.flatten(steps_completion);
            for (var i = 0; i < states.length; i++)
                states[i] = states[i] ? 1 : 0;

            return parseInt(states.reverse().join(''), 2);
        },

        go : function (direction) {
            $state.go(target(direction), params);
        },

        step : function (number) {
            step_current = number;
            $state.go(steps_views[step_current][0], params);
        },

        views : function () {
            return steps_views[step_current];
        },

        view_set : function () {
            for (var i = 0; i < steps_views.length; i++)
                for (var j = 0; j < steps_views[i].length; j++)
                    if (steps_views[i][j] === $state.current.name) {
                        step_current = i;
                        view_current = j;
                    }

            var s = $state.current.name.split('.');
            return s[s.length - 1];
        },

        view_get : function () {
            return view_current;
        },

        view_complete : function () {
            return steps_completion[step_current][view_current];
        },

        place_set : function (place) {
            params['place_id'] = place.id;
        },

        completion_set : function (integer) {
            integer = integer ? integer : 0;
            steps_completion = [[], [], []];

            var binary = integer.toString(2);
            var states = binary.split('').reverse();
            var count = 0;

            for (var i = 0; i < steps_views.length; i++)
                for (var j = 0; j < steps_views[i].length; j++) {
                    steps_completion[i].push(false);

                    if (states[count] && states[count] === '1')
                        steps_completion[i][j] = true;

                    count++;
                }
        },

        completion_total_get : function (integer) {
            var binary = integer.toString(2);
            var states = binary.split('').reverse();
            var count = 0;
            var completion = 0;

            for (var i = 0; i < steps_views.length; i++)
                for (var j = 0; j < steps_views[i].length; j++) {
                    if (states[count] && states[count] === '1')
                        completion++;

                    count++;
                }

            return Math.round(completion / count * 100);
        }
    };
}])

.constant('uibButtonConfig', {
    activeClass : 'btn-primary'
})

.controller('PlaceFormMain',
    ['$rootScope', 'Account', '$stateParams', 'FormManager', '$state', 'Place', 'Modal', 'Processing',
     '$location', '$anchorScroll', 'formOnly',
    function ($rootScope, Account, $stateParams, FormManager, $state, Place, Modal, Processing,
              $location, $anchorScroll, formOnly) {
    var vm = this;
    var view_name = false;
    vm.manager = FormManager;
    var host_id = false;

    Account.current()
        .then(function (account) {
            vm.account = account;
        });

    var init = function () {
        view_name = vm.manager.view_set();

        switch (view_name) {
            case 'address':
                if ($state.params.host_id)
                    host_id = $state.params.host_id;

                if (vm.place.id && vm.place.address_v1) {
                    vm.place_search = vm.place.address_v1;
                    vm.place.address_v1 = '';
                }

                break;

            case 'management':
                if (vm.manager.view_complete())
                    vm.approval_check = true;
                break;

            case 'timings':
                vm.day_hours = [];
                for (var i = 8; i <= 24; i++)
                    vm.day_hours.push(i + ':00');

                break;

            case 'duration':
                vm.nights_max_enable = vm.place.nights_max ? true : false;
                break;
        }
    };

    $rootScope.$on('$stateChangeSuccess', init);

    vm.place = {};
    if ($stateParams.place_id)
        Place.preferences($stateParams.place_id)
            .then(function (place) {
                vm.place = place;
                vm.manager.place_set(place);
                vm.manager.completion_set(place.completion);
                init();
            })
    else {
        vm.manager.completion_set();
        init();
    }

    vm.go = function (view, place_id) {
        $state.go(view, {'place_id' : place_id})
    };

    var parent = function (e, name) {
        while (e.parentNode) {
            e = e.parentNode;
            if (e.className.indexOf(name) > -1)
                return e;
        }
        return null;
    }

    vm.save = function (direction) {
        var form = vm.forms[view_name];

        form.$setSubmitted();

        if (form.$invalid) {
            var e = angular.element("[name='" + form.$name + "']").find('.ng-invalid:visible:first')[0];
            var p = parent(e, 'form-group');
            $location.hash(p.id);
            $anchorScroll();
            vm.bounce = true;
            return;
        }

        if (!vm.place.id)
            Place.add(vm.place, host_id)
                .then(function (place) {
                    vm.place = place;
                    vm.manager.place_set(place);
                    vm.manager.go(direction);

                    vm.account.host.places_count = 1;
                    vm.account.update(vm.account);
                });
        else {
            vm.place.completion = vm.manager.completion_get();

            Place.update(vm.place, formOnly[view_name], {view: view_name})
                .then(function () {
                    vm.manager.go(direction);
                });
        }
    };

    vm.errors = function () {
        var form = vm.forms[view_name];

        if (!form || !form.$submitted)
            return;

        var count = 0;
        var fields = [];
        angular.forEach(form.$error,
            function(val) {
                if(angular.isArray(val)) {
                    for (var i = 0; i < val.length; i++) {
                        if (fields.indexOf(val[i].$name) == -1)
                            count++;

                        fields.push(val[i].$name);
                    };
                }
            });

        return count;
    };

    vm.upload = function (file) {
        var datas = new FormData();
        datas.append('file', file);

        Place.picture_add(datas)
              .then(function (response) {
                    if (response.result === 'ok') {
                        if (!vm.place.pictures)
                            vm.place.pictures = [];

                        vm.place.pictures.push({ url : response.url, id: response.id });
                    } else {
                        var title = 'Incorrect file : ' + response.name;
                        var body = 'Your file could not be uploaded. <br>' +
                                   'Only files with a size under 3 Mo ' +
                                   'and with the correct format (png, jpg, gif) are authorized.';

                        Modal.inform(title, body);
                    }

                }, function () {
                    // close modal
                    console.log('serveur error');
                });
    };

    vm.uploads = function (files) {
        angular.forEach(files,
            function(file) {
                vm.upload(file);
            });
    };

    vm.picture_remove = function(index) {
        var title = 'Remove this file ?';
        var body = 'Your file will be removed';

        Modal.confirm(title, body)
             .then(function () {
                vm.place.pictures.splice(index, 1);
             });
    };

    var compare = function (form, fields, state) {
        for (var i = 0; i < fields.length; i++)
            if (form[fields[i]])
                form[fields[i]].$setValidity('compare', state);
    };

    vm.beds_compare = function () {
        var fields = ['single', 'double', 'bunk', 'king', 'queen', 'sofa', 'couch'];

        var total_beds = 0;
        for (var i = 0; i < fields.length; i++)
            total_beds += vm.place['beds_' + fields[i]];

        fields.push('beds');
        compare(vm.forms.space, fields, vm.place.beds === total_beds);
    };

    vm.nights_compare = function () {
        var fields = ['nights_min', 'nights_max'];

        if (vm.nights_max_enable)
            compare(vm.forms.duration, fields, vm.place.nights_min <= vm.place.nights_max);
    };

    vm.place_set = function () {
        var address = this.getPlace();

        var street = '';
        var postal_code = '';
        var city = '';
        var country = '';

        if (!address.address_components) {
            vm.place_search = '';
            return;
        }

        for (var i = 0; i < address.address_components.length; i++) {
            var c = address.address_components[i];

            if (c.types.indexOf('street_number') != -1)
                street = c.long_name;

            if (c.types.indexOf('route') != -1)
                street += ' ' + c.long_name;

            if (c.types.indexOf('locality') != -1)
                city = c.long_name;

            if (c.types.indexOf('postal_code') != -1)
                postal_code = c.long_name;

            if (c.types.indexOf('country') != -1)
                country = c.long_name;
        };

        vm.place.address = {
            street : street,
            postal_code : postal_code,
            city : city,
            country : country,
        }

        vm.place_search = '';
    };

}])


.directive('footer', function() {
    return {
        templateUrl: require('./footer.html'),
    };
})

.directive('preventEnterSubmit', function () {
    return function (scope, el, attrs) {
        el.bind('keydown', function (event) {
            if (13 == event.which) {
                event.preventDefault();
                window.stop();
                document.execCommand('Stop');
                return false;
            }
        });
    };
})

.filter('viewName', function () {
    return function (state) {
        if (!state)
            return;

        var names = {
            'interlocutor' : 'Laws & Taxes'
        }

        var s = state.split('.');
        var state = s[s.length - 1];

        if (names[state])
            var name = names[state]
        else
            var name = state.charAt(0).toUpperCase() + state.slice(1);

        return name;
    };
})

.directive('focusMe',
    ['$timeout', '$location', '$anchorScroll',
    function ($timeout, $location, $anchorScroll) {
    return {
        link: function (scope, element, attrs) {
            var parent = function (e, name) {
                while (e.parentNode) {
                    e = e.parentNode;
                    if (e.className.indexOf(name) > -1)
                        return e;
                }
                return null;
            };

            var click = function() {
                $timeout(function () {
                    var p = parent(element[0], 'form-group');
                    $location.hash(p.id);
                    $anchorScroll();
                }, 500);
            };

            element.bind('click', click);
        }
    };
}])
