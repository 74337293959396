angular.module('import', [])

.factory('Import',
    ['$q', 'Restangular',
    function ($q, Restangular) {

    var current_all;
    return {
        all: function (params) {
            if (current_all) current_all.resolve();
            current_all = $q.defer();
            return Restangular.all('synchronizations')
                        .withHttpConfig({timeout: current_all.promise})
                        .getList(params);
        },

        get: function (import_id) {
          return Restangular.one('synchronizations', import_id).get();
        },

        confirm: function(data) {
          return Restangular
            .all('synchronizations')
            .customPOST(data, 'confirm')
        },

        update_from_place: function(place) {
          return Restangular
            .all('synchronizations')
            .customPUT(place, 'update-from-place')
        }

        /*unverified: function () {
            return Restangular.all('reservations').customGET('unverified');
        },

        add: function(reservation) {
            return Restangular.all('reservations').post(reservation);
        },

        last: function (place_id) {
            return Restangular.all('reservations').one('last', place_id).get();
        },

        search_by_code: function(code){
          return Restangular.all('reservations').one('code', code).get();
        }*/
    };
}])
