angular.module('place', [])

.factory('Place',
    ['$q', 'Restangular',
    function ($q, Restangular) {

    var current_all;
    var current_calendar;

    function json_date(date) {
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
    }

    return {
        all: function (params) {
            if (current_all) current_all.resolve();
            current_all = $q.defer();
            return Restangular.all('places')
                              .withHttpConfig({timeout: current_all.promise})
                              .getList(params);
        },

        add: function (place, host_id) {
            if (host_id)
                return Restangular.all('places').post(place, {'host_id': host_id});
            return Restangular.all('places').post(place);
        },

        update: function (place, only, query_args) {
            if (only) {
                only.push('id');
                only.push('completion');
            }
            const params = only && only.length > 0 ? _.pick(place, only) : place;

            return Restangular.one('places', place.id).customPUT(params, "", query_args);
        },

        update_other_informations: function (place) {
            return Restangular.one('places', place.id)
                .one('other_informations')
                .customPUT(place);
        },

        delete: function (place_id) {
            return Restangular.one('places', place_id).remove();
        },

        get: function (place_id, excluded_fields = []) {
            return Restangular.one('places').customGET(place_id, {excluded_fields: excluded_fields});
        },

        configure: function (place, only) {
            if (only) {
                only.push('id');
                only.push('completion');
            }
            const params = only && only.length > 0 ? _.pick(place, only) : place;

            return Restangular.one('places', place.id).customPUT(params, "configure");
        },

        calendar: function (params_) {
            if (current_calendar) current_calendar.resolve();
            current_calendar = $q.defer();

            const params = angular.copy(params_);

            var date = new Date(params.from);
            date.setDate(date.getDate() + 6);

            params.to = json_date(date);
            return Restangular.all('places')
                        .withHttpConfig({timeout: current_calendar.promise})
                        .customGET('calendar', params);
        },

        statistics: function(place_id) {
            const params = place_id ? {place_id: place_id} : {};
            return Restangular.all('places').customGET('statistics', params);
        },

        preferences: function (place_id) {
            return Restangular.one('places', place_id).customGET('preferences');
        },

        search: function (q) {
            return Restangular.all('places').customGET('search', {'q' : q});
        },

        picture_add: function (datas) {
            return Restangular.all('places')
                    .withHttpConfig({transformRequest: angular.identity})
                    .customPOST(datas, 'pictures', undefined, {'Content-Type': undefined});
        },

        clean: function(place_id, clean) {
            return Restangular.one('places', place_id)
                              .customPOST({'clean' : clean}, 'clean');
        },

        is_available: function (place_id, from, to, reservation_edit) {
            var params = {'from' : json_date(from), 'to' : json_date(to) };
            if (reservation_edit)
                params.reservation = reservation_edit;

            return Restangular.one('places', place_id)
                              .customGET('availabilities', params);
        },

        availabilities: function (place_id, update) {
            if (update) {
                var periods = [];
                angular.forEach(update.periods, function (period) {
                    periods.push({'from_date': json_date(period.from_date), 'to_date': json_date(period.to_date)});
                });
                return Restangular.one('places', place_id)
                                  .customPOST(periods, 'availabilities', {availability: update.availability});
            }
            var deferred = $q.defer();

            function parse_date (elems) {
                angular.forEach(elems, function (elem) {
                    elem.from_date = new Date(elem.from_date);
                    elem.from_datetime = angular.copy(elem.from_date);
                    elem.from_date.setHours(0, 0, 0, 0);
                    elem.to_date = new Date(elem.to_date);
                    elem.to_datetime = angular.copy(elem.to_date);
                    elem.to_date.setHours(0, 0, 0, 0);
                });
            }

            Restangular.one('places', place_id)
                       .customGET('availabilities')
                       .then(function (availability) {
                            parse_date(availability.periods);
                            parse_date(availability.reservations);
                            deferred.resolve(availability);
                       },
                       function (error) { deferred.reject(error); });

            return deferred.promise;
        },

        activity: function (params) {
            return Restangular.all('places/activity').getList(params);
        },

        ical_import: function (place_id) {
            return Restangular.one('icalendar', place_id)
                .customPOST({}, 'imports');
        },

        divergent_reservations: function (place_id) {
          return Restangular.one('places', place_id)
            .customGET('divergent_reservations');
        },

        bookings: function (place_id, page) {
            return Restangular.one('places', place_id)
                .customGET('bookings', {per_page: 20, page: page});
        },

        airbnb_link: function(id, place_data) {
            return Restangular
                .one('places', id)
                .one('airbnb_link')
                .customPUT(place_data, '', '', {'X-LISTING-ID': id});
        }
    };
}]);
