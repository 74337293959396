angular.module('payment')

  .controller('Payments',
    ['$scope', 'Payment', 'Modal', '$uibModal', '$timeout', '$state', '$stateParams',
      function ($scope, Payment, Modal, $uibModal, $timeout, $state, $stateParams) {
        var vm = this;
        vm.params = {
          date: $stateParams.date || dayjs().format('MM-YYYY')
        }

        vm.get_payments = function () {
          return Payment.all(vm.params).then((d) => {
            this.payments = d.payments
            this.meta = d.meta
            this.meta.last_refresh = new Date(d.meta.last_refresh + ' UTC')
          })
        };

        vm.generate_report = function (host_id) {
          $state.go('app.payments.report', {'host': host_id, date: vm.params.date})
        };

        vm.refresh_payments = function () {
          const modal = $uibModal.open({
            animation: false,
            backdrop: 'static',
            windowClass: 'loading-modal',
            template: '... Calculating payments',
            size: 'sm',
          });
          Payment
            .refresh(vm.params.date)
            .then(function () {
              return vm.get_payments()
            })
            .then(function () {
              modal.close()
            })
        };

        vm.get_selectable_dates = function () {
          var dates = [];
          var startDate = dayjs().subtract(24, 'month');
          var endDate = dayjs();

          while (startDate <= endDate) {
            dates.push(startDate.format('MM-YYYY'));
            startDate = startDate.add(1, 'month')
          }

          return dates;
        };

        vm.change_date = function () {
          $state.go('.', {date: vm.params.date});
        };

        vm.get_current_month = function () {
          dayjs.extend(dayjs_plugin_customParseFormat)
          return dayjs(vm.params.date, 'MM-YYYY').format('MMMM')
        };

        vm.get_status_badge = function (transfer) {
          if (transfer === 0) {
            return '<div class="status-pending">PENDING</div>'
          }
          return '<div class="status-transferred">TRANSFERRED</div>'
        };

        vm.update_payment_status = function (payment) {
          Payment.update_status(payment, vm.params.date)
        };

        vm.toLocaleDate = function (date) {
          return new Date(date + ' UTC');
        };

        vm.get_payments();


      }])

  .controller('PaymentReport', ['$scope', 'Payment', 'Modal', '$uibModal', '$timeout', '$stateParams',
    function ($scope, Payment, Modal, $uibModal, $timeout, $stateParams) {

      var vm = this;

      vm.params = {
        date: $stateParams.date,
        host_id: $stateParams.host
      }

      vm.init = function () {
        Payment.report(vm.params).then(function (d) {
          vm.report = d;
          vm.report.report_sent = d.report_sent ? new Date(d.report_sent + ' UTC') : false;
        });
      };

      vm.send_report = function () {
        Modal.confirm('Send report', 'Do you really want to send the report?').then(function () {
          Payment.report_send(vm.params).then(function (d) {
            vm.init()
          });
        });
      };

      vm.init()


    }]);
