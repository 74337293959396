angular.module('messaging', [])
    .factory('Message',
        [ 'Restangular', '$q',
            
            function (Restangular, $q) {
                return {
                    all: function (params) {
                        return Restangular.all('threads').getList(params);
                    },
                    select: function (q) {
                        return Restangular.all('threads').customGET('',{'q': q});
                    },
                    get: function (contact_id) {
                        return Restangular.one('threads',contact_id).one('messages').get();
                    },
                    post: function (contact_id, message) {
                        return Restangular.one('threads',contact_id).one('messages').customPOST({message: message},'');
                    },
                    post_image: function (contact_id, image) {
                        return Restangular.one('threads',contact_id).one('medias').withHttpConfig({transformRequest: angular.identity}).customPOST(image, '', undefined, {'Content-Type': undefined});
                    },
                    change_read_status: function (contact_id, status) {
                        return Restangular.one('threads', contact_id).one('read').customPUT({status:status}, '')
                    },
                    quick_answers: function () {
                        return $q(function(resolve, reject) {
                            // var answers = ["Message1", "message2"]
                            var answers = require('./answers.json')
                            resolve(answers);
                        });
                    }
                }
            }
        ]
    )
