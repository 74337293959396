angular.module('cleaning')
  .constant('maidStatus', {
    'DEACTIVATED': {'id': 0, 'label': 'Deactivated', 'class': 'default'},
    'ACTIVATED': {'id': 1, 'label': 'Activated', 'class': 'info'},
  })

  .controller('Services',
    ['$state', 'Maid', 'actions', 'Modal', 'maidStatus', 'Account', '$scope', '$uibModal',
    function ($state, Maid, actions, Modal, maidStatus, Account, $scope, $uibModal) {
      var vm = this;

      vm.actions = actions;
      vm.maidStatus = maidStatus;

      vm.params = {
        place_id: $state.params.place_id,
        action: null,
        search: null,
        page: 1,
        per_page: 20
      }

      vm.maid_load = function (resetPage) {
        if (resetPage)
          vm.params.page = 1

        Maid.all(vm.params)
            .then(function (d) {
              vm.maids = d
            })
      }

      vm.maid_add = function () {
        const maid = {
          name: '',
          phone: '',
          status: maidStatus.DEACTIVATED.id,
          user: {email: ''}
        }

        var modal_scope = $scope.$new();
        modal_scope.maid = maid;

        modal_scope.modal = $uibModal.open({
          templateUrl: require('./maid.modal.html'),
          scope: modal_scope
        });

        modal_scope.valid = function () {
          modal_scope.error = false;

          Maid.add(modal_scope.maid)
            .then((data) => {
              Maid.all(vm.params).then(d => vm.maids = d)
              vm.password_create(data)
              modal_scope.modal.close();
            }, function (r) {
              if (r.data.errors.email)
                modal_scope.error = r.data.errors.email;
            })
        };
      }

      vm.password_create = function (maid) {
        Account.password_create(maid.user.id)
          .then(function () {
            const title = 'Password create';
            const content = 'An email has been sent to ' + maid.user.email + '.';

            Modal.inform(title, content);
          }, function (r) {
            vm.error = r.data.errors.email;
          });
      };

      vm.maid_edit = function (maid) {
        var modal_scope = $scope.$new();
        modal_scope.maid = angular.copy(maid);

        modal_scope.modal = $uibModal.open({
          templateUrl: require('./maid.modal.html'),
          scope: modal_scope
        });

        modal_scope.valid = function () {
          modal_scope.error = false;

          Maid.update(modal_scope.maid)
            .then((data) => {
              Maid.all(vm.params).then(d => vm.maids = d)
              modal_scope.modal.close();
            }, function (r) {
              if (r.data.errors.email)
                modal_scope.error = r.data.errors.email;
            })
        };
      };

      vm.maid_delete = function (id) {
        Modal.confirm('Confirm', 'Do you really want to delete this Cleaning service account ?')
          .then(() => {
            Maid
              .delete(id).then(() =>
              Maid.all(vm.params).then((d) => vm.maids = d)
            )
          })
      };

      vm.maid_notify = function (maid) {
        Modal.confirm('Send mail to ' + maid.name, 'Do you want to inform the maid that an account has been created?')
          .then(() => {
            vm.password_create(maid)
          })
      }

      vm.maid_load();
    }]);
