angular.module('messaging')
    .controller('Messaging',
        ['$rootScope', '$state', 'Reservation', '$timeout', 'Message', 'configs', '$q', '$scope', '$uibModal', '$uibModalStack',
            function ($rootScope, $state, Reservation, $timeout, Message, configs, $q, $scope, $uibModal, $uibModalStack) {
                var vm = this;
                $state.vm = vm

                vm.threads = []
                vm.thread = {}
                vm.selected_contact = {}
                vm.reservation = null
                vm.all_threads_is_unrecoverable = false
                vm.thread_is_unrecoverable = false
                vm.params = {
                    page: 1,
                    per_page: 50,
                    total_thread: 100
                }

                vm.get_threads = function () {
                    Message.all(vm.params)
                    .then(function (threads) {
                        vm.threads = threads
                        vm.params.total_thread = threads.total
                        vm.all_threads_is_unrecoverable = false
                        if(threads.length > 0){
                            vm.display_thread(threads[0])
                        }
                    }).catch(function (response) {
                        vm.all_threads_is_unrecoverable = true
                        console.log('Unable to retrieve threads. Error: ', response)
                    });
                }
                vm.get_threads()
                
                vm.get_thread = function (contactId) {
                    Message.get(contactId)
                    .then(function (thread) {
                        thread.messages.reverse();
                        thread.messages.forEach((mes) => {
                            if (mes.message.includes('\n')) {
                                mes.message = mes.message.replace(new RegExp('\r?\n', 'g'), '<br />')
                            }
                        })
                        thread['id'] = contactId
                        vm.thread = thread
                        //vm.toggle_read(thread, 1)
                        vm.thread_is_unrecoverable = false
                        scrollDiscussionBottom()
                    }).catch(function (response) {
                        vm.thread_is_unrecoverable = true;
                        console.log('recovery', vm.thread_is_unrecoverable)
                        console.log('An error occurs. Unable to find the corresponding thread. Error: ', response)
                    });
                }

                vm.answers = {}
                vm.get_answers = function () {
                    Message.quick_answers()
                        .then(function (answers) {
                            vm.answers = answers.answers;
                        })
                }
                // vm.get_answers();

                vm.quick_answer = "";
                vm.add_quick_answers = function () {
                    var deferred = $q.defer();

                    var modal_scope = $scope.$new();

                    vm.get_answers();

                    modal_scope.modal = $uibModal.open({
                        templateUrl: require('./quick-answers.modal.html'),
                        scope: modal_scope
                      });

                    modal_scope.update_answer = function (id) {
                        $uibModalStack.dismissAll();
                        // vm.quick_answer = vm.answers[id].content;
                        vm.message = vm.answers[id].content;

                        // $scope.quick_answer = vm.quick_answer
                    }

                    document.querySelector('.autoExpand').focus()
                    

                    return deferred.promise;
                }

                

                vm.display_thread = function (contact) {
                    vm.get_thread(contact.id, contact)
                    vm.selected_contact = contact
                    vm.toggle_read(vm.selected_contact, 1)
                    vm.get_reservation(vm.selected_contact.reservation_confirmation_code)
                }

                vm.get_reservation = function (reservation_code) {
                    Reservation.search_by_code(reservation_code).then(function (reservation) {
                        vm.reservation = reservation
                        vm.reservation.date = new Date(vm.reservation.date);
                        vm.reservation.from_date = new Date(vm.reservation.from_date);
                        vm.reservation.to_date = new Date(vm.reservation.to_date);
                    }).catch(() => {
                        vm.reservation = null
                    })
                }


                vm.search = function (query) {
                    Message.select(query)
                        .then(function (threads) {
                            vm.threads = threads.items
                            vm.params.page = 1
                            vm.params.total_thread = threads.total
                        }).catch(function (response) {
                        console.log('An error occurs during the research. Unable to find a corresponding thread. Error: ', response)
                    });
                }

                vm.toggle_read = function (thread, newStatus) {
                    Message.change_read_status(thread.id, newStatus)
                        .then(function () {
                            if (thread.status === undefined || thread.status === newStatus) {
                                return
                            }
                            thread.status = newStatus
                            const event = newStatus === 1 ? 'conversationRemoved' : 'conversationAdded'
                            $rootScope.$broadcast(event)
                        }).catch(function (response) {
                        console.log('An error occurs. Unable to change the status of the thread. Error: ', response)
                    });
                }

                vm.mark_all_as_read = function () {
                    vm.threads.forEach((thread) => {
                        if (thread.status === 0) {
                            vm.toggle_read(thread, 1)
                        }
                    })
                }

                vm.post_message = function (contact_id, message) {
                    Message.post(contact_id, message)
                        .then(function (response) {
                            console.log('message envoyé', response)
                            vm.message = ""
                            vm.thread.messages.push(response.message)
                            document.getElementById("autoExpand").rows = 1
                        }).catch(function (response) {
                        console.log('An error occurs. Unable to send the message. Error: ', response)
                    });
                }

                vm.picture_upload = function (contact_id, file) {
                    var datas = new FormData();
                    datas.append('image', file);

                    Message.post_image(contact_id, datas)
                        .then(function (response) {
                            console.log('image envoyée', response)
                            vm.thread.messages.push(response.message)
                        }).catch(function (response) {
                        console.log('An error occurs. Unable to upload the picture. Error: ', response)
                    });
                };


                vm.recover_date_element = function (date) {
                    var day = new Date(date).getDate();
                    var month = new Date(date).getMonth();
                    var year = new Date(date).getFullYear();

                    return {day: day, month: month, year: year}
                }

                vm.recover_time_element = function (date) {
                    var hour = date.getHours();
                    hour = hour < 10 ? "0" + hour.toString() : hour.toString()
                    var minutes = date.getMinutes();
                    minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString()
                    var halfDay = date.getHours() > 12 ? 'PM' : 'AM';

                    return {hour: hour, minutes: minutes, halfDay: halfDay}
                }

                vm.format_day_and_month = function (date) {
                    var month = new Date(date).toLocaleString('EN', {month: 'short'})
                    var day = new Date(date).getDate();
                    day = day < 10 ? "0" + day.toString() : day.toString()

                    return {month: month, day: day}
                }

                vm.display_updated_date = function (update) {
                    var displayed_date = "";
                    //check for  today
                    //var newdate = new Date (Date.now())
                    var updated_date = new Date(update)
                    var today = vm.recover_date_element(Date.now())
                    var updated_time = vm.recover_time_element(updated_date);

                    if (updated_date.getDate() === today.day && updated_date.getMonth() === today.month && updated_date.getFullYear() === today.year) {
                        displayed_date = `${updated_time.hour}:${updated_time.minutes} ${updated_time.halfDay}`;
                    } else {
                        var updated_day = new Date(updated_date).getDate();
                        updated_day = updated_day < 10 ? "0" + updated_day.toString() : updated_day.toString()

                        displayed_date = `${updated_date.toLocaleString('EN', {month: 'short'})} ${updated_day} ${updated_date.getFullYear() === today.year ? '' : updated_date.getFullYear()}`;
                    }

                    return displayed_date
                }

                vm.display_reservation_period = function (startDate, endDate) {
                    var start = vm.format_day_and_month(startDate);
                    var end = vm.format_day_and_month(endDate);

                    var period = start.month === end.month
                        ? `${start.month} ${start.day} - ${end.day}`
                        : `${start.month} ${start.day} - ${end.month} ${end.day}`;

                    return period;
                }

                vm.display_date_banner = function (message_date) {
                    var moment = "";
                    var date = "";

                    //check for yesterday and today
                    //var last_message_date = new Date(Date.now()-(24*60*60*1000))
                    //var last_message_date = new Date (Date.now())
                    var new_message_date = new Date(message_date);
                    var today = vm.recover_date_element(Date.now())
                    var yesterday = vm.recover_date_element(Date.now() - (24 * 60 * 60 * 1000))

                    if (new_message_date.getDate() === today.day && new_message_date.getMonth() === today.month && new_message_date.getFullYear() === today.year) {
                        date = "Today";
                    } else if (new_message_date.getDate() === yesterday.day && new_message_date.getMonth() === yesterday.month && new_message_date.getFullYear() === yesterday.year) {
                        date = "Yesterday";
                    } else {
                        date = `${new_message_date.toLocaleString('EN', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        })}`;
                    }

                    var time = vm.recover_time_element(new_message_date);
                    moment = `${date} · ${time.hour}:${time.minutes} ${time.halfDay}`;

                    return moment;
                }

                vm.display_reservation_banner = function () {
                    var start = vm.format_day_and_month(vm.selected_contact.checkin);
                    var end = vm.format_day_and_month(vm.selected_contact.checkout);

                    var guests = vm.selected_contact.number_of_guests > 1 ? `${vm.selected_contact.number_of_guests} guests` : `${vm.selected_contact.number_of_guests} guest`
                    return `Request received · ${start.day} ${start.month} - ${end.day} ${end.month} · ${guests}`
                }

                vm.display_reservation_details = function (reservation) {
                    var start = vm.format_day_and_month(reservation.from_date);
                    var end = vm.format_day_and_month(reservation.to_date);

                    var details = end.month === start.month ? `${start.month} ${start.day} - ${end.day}` : `${start.month} ${start.day} - ${end.month} ${end.day}`

                    details = `${details}`
                        + ` · ${reservation.night_count} ${reservation.night_count > 1 ? 'nights' : 'night'}`
                        + ` · ${reservation.guest_count} ${reservation.guest_count > 1 ? 'guests' : 'guest'}`

                    return details
                }

                vm.approve_request = function () {
                    Reservation.accept_or_deny(vm.reservation.code, 'accept').then(function (reservation) {

                    })
                }

                vm.decline_request = function () {
                    Reservation.accept_or_deny(vm.reservation.code, 'deny').then(function (reservation) {

                    })
                }

                vm.knf_reservation_link = function () {
                    return $state.href('app.place.reservations.reservation', {reservation_id: vm.reservation.id})
                }

                vm.reservation_link = function () {
                    return `https://www.airbnb.fr/hosting/reservations?confirmationCode=${vm.reservation.code}`
                }

                vm.keypress_message = function (e) {
                    if (e.keyCode === 13 && e.shiftKey) {
                        return false
                    }
                    if (e.keyCode === 13) {
                        vm.post_message(vm.selected_contact.id, vm.message)
                    }
                }

                vm.page_change = function () {
                    vm.get_threads()
                }

                vm.rows = 0

                function getScrollHeight(elm) {
                    var savedValue = elm.value
                    elm.value = ''
                    elm._baseScrollHeight = elm.scrollHeight
                    elm.value = savedValue
                }

                function onExpandableTextareaInput({target: elm}) {
                    if (!elm.classList.contains('autoExpand') || !elm.nodeName == 'TEXTAREA') return

                    var minRows = elm.getAttribute('data-min-rows') | 0, rows;
                    !elm._baseScrollHeight && getScrollHeight(elm)

                    elm.rows = minRows
                    vm.rows = Math.floor((elm.scrollHeight - elm._baseScrollHeight) / 18)

                    elm.rows = vm.rows > 2 ? minRows + 2 : minRows + vm.rows
                }

                function scrollDiscussionBottom(){
                    setTimeout(() => {
                        const discussion = document.querySelector('.discussion-area')
                        discussion.scrollTop = discussion.scrollHeight
                    }, 0)
                }

                document.addEventListener('input', onExpandableTextareaInput)

                const messageEvt = new EventSource(`${configs.api}/stream`)

                messageEvt.onmessage = function (event) {
                    $timeout(function () {
                        const data = JSON.parse(event.data)
                        console.log(data)
                        const threadId = data.thread.id
                        const thread = vm.threads.find(t => t.id === threadId)
                        if (!thread) {
                            return
                        }
                        if (vm.selected_contact.id === threadId) {
                            vm.get_thread(threadId)
                            return
                        }
                        thread.status = 0
                        thread.updated_at = data.thread.updated_at
                        $rootScope.$broadcast('conversationAdded')
                    }, 1000)
                }

                $rootScope.$on('$stateChangeStart', function (e, confirmation) {
                    messageEvt.close()
                });
            }
        ]
    );
