angular.module('host', [])

.factory('Host',
    ['Restangular', '$q',
    function (Restangular, $q) {

    return {
        all: function(params) {
            return Restangular.all('hosts').getList(params);
        },

        get: function(host_id) {
            return Restangular.one('hosts', host_id).get();
        },

        application_fee: function(host_id, application_fee) {
            return Restangular.one('hosts', host_id)
                        .customPOST({ 'application_fee' : application_fee }, 'fee');
        },

        update: function (host) {
            return Restangular.one('hosts', host.id).customPUT(host);
        },

        create_place: function (host_id, create_airbnb, name) {
            return Restangular.one('places').customPOST(
                { name },
                "",
                { host_id, create_airbnb: Boolean(create_airbnb) }
            );
        },
    };
}]);